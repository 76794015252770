import React, { useState } from "react";
import contactX from "../../assets/contactX.png";
import { ContactUsCard } from "./ContactUsCard";
import contactUs from "../../assets/Contact-us.svg";
// import { contactApi } from "../../api/api";
import axios from "axios";

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [budget, setBudget] = useState("");
  const [description, setDescription] = useState("");

  const handleChange = async () => {
    try {
      if (!name || !email || !mobile || !budget || !description) {
        alert("Please fill the mandatory fields");
        return;
      }

      const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        alert("Please enter a valid email");
        return;
      }

      const mobileRegex = /^\d{10}$/;
      if (!mobileRegex.test(mobile)) {
        alert("Please enter a valid 10-digit mobile number.");
        return;
      }

      const budgetRegex = /^\d+$/;
      if (!budgetRegex.test(budget)) {
        alert("Please enter a valid numeric value for the budget.");
        return;
      }

      const { data } = await axios.post("https://apipando.pandoconsultants.com/api/contact", {
        name,
        email,
        mobile,
        budget,
        description,
      });

      if (data.success) {
        alert("Form submit successfully");
        setName("");
        setEmail("");
        setMobile("");
        setBudget("");
        setDescription("");
        return;
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };

  return (
    <div className="w-full">
      <div className="font-sans">
        <div className="flex flex-col-reverse items-center w-full mx-auto md:flex-row gap-20">
          <div className="lg:w-full md:w-full w-full md:pl-8 lg:pl-32 p-4">
            <p className=" text-6xl text-transparent bg-clip-text bg-gradient-to-r from-[#53BCAC] to-[#F0F0F0] font-bold leading-normal">
              Contact Us
            </p>
            <p className="w-full leading-7 text-justify text-[#484848]">
              Pando India Software Consultants is USA based on Market research and Finance Software
              services with a brilliant team and technology.
            </p>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Name<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your Name"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Email Address<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your Email Address"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Mobile Number<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Enter your Mobile Number"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Enter your Budget<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="text"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  placeholder="Enter your Budget"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Project Description<span className="text-red-500 text-xl"> *</span>
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="block border-grey-400 text-black focus:outline-none p-2.5 lg:w-9/12 md:w-full w-full border text-sm rounded-lg "
                  placeholder="Project description"
                ></textarea>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full my-2 ">
                <div className="">
                  <button
                    type="submit"
                    className="bg-orange-500 h-10 text-lg text-black mb-5 lg:w-9/12 md:w-full w-full rounded-md font-medium py-1 text-white"
                    onClick={handleChange}
                  >
                    Submit
                  </button>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="relative w-full lg:w-full md:w-full flex">
              <img src={contactUs} alt="" className="maw-full ml-auto" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-8">
        <ContactUsCard />
      </div>
    </div>
  );
};
