import React from "react";

const DigitallyEmpoweringPage = () => {
  return (
    <div id="digitally-empowering" className="flex items-center justify-center">
      <div>
        <div className="md:mx-28 md:mt-20">
          <div className="px-6 py-4">
            <h2 className="mb-2 text-3xl font-bold">
              Digitally Empowering Brands and Startups that are shaping our lives every day
            </h2>
            <br />
            <p className="text-base text-gray-700">
              According to a recent industry axiom, every business, whether a fast-food restaurant
              or a manufacturer of industrial gear, is now a tech company. According to the theory,
              all businesses are increasingly data-driven. Whether consumers or B2B customers are
              buying, most people expect their preferred brands or vendors to have a digital
              storefront or interface.
            </p>
            <br />
            <p>
              Truly, all organizations are evolving into tech enterprises. To drive the next wave of
              innovation, they are investing in creating teams of data scientists, software
              engineers, UI experts, and others. The next frontier for banks, grocery stores, and
              biopharmaceutical firms appears to be online.
            </p>
            <br />
            <p>
              Built In is continually examining the direction the IT sector is taking and how it
              will get there. It&apos;s becoming more and more obvious to us that the newest
              innovations from Silicon Valley startups or from companies like Facebook, Amazon,
              Apple, Microsoft, and Google just represent a portion of the whole picture. There is a
              lot of innovation going on that is not typically covered by tech media. We want to
              change the emphasis.
            </p>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default DigitallyEmpoweringPage;
