import React from "react";
import { HashLink } from "react-router-hash-link";
import marketResearch from "../../assets/Solution-icon/market-research.svg";
import blockChain from "../../assets/Solution-icon/block-chain-technology.svg";
import softwareDev from "../../assets/Solution-icon/software-development.svg";
import onlineTrading from "../../assets/Solution-icon/online-trading.svg";
import CRM from "../../assets/Solution-icon/crm-dashboard.svg";
import devops from "../../assets/Solution-icon/devops.svg";
import machineLearning from "../../assets/Solution-icon/machine-learning.svg";
import cloud from "../../assets/Solution-icon/cloud-sercive.svg";

export const Solution = ({ toggle }: { toggle: () => void }) => {
  return (
    <div className="absolute left-0 top-[80px] w-full h-auto">
      <div className="w-full relative bg-[#FFFFFF] text-center bg-gray-50">
        <div className="w-10/12 inline-block">
          <ul className="list-none flex flex-wrap justify-start pb-10">
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={marketResearch} alt="market-research" className="absolute pt-2" />
              <HashLink to="/market-research" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Market Research</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Market research uncovers consumer insights,
                <p className="text-[#565656]">driving business success</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={blockChain} alt="market-research" className="absolute pt-2" />
              <HashLink to="/block-chain" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Block Chain technology</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Securely revolutionizing industries through
                <p className="text-[#565656]">decentralized technology.</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={softwareDev} alt="market-research" className="absolute pt-2" />
              <HashLink to="/software-development" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Software Development</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Creating digital solutions that drive innovation
                <p className="text-[#565656]">and efficiency.</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={onlineTrading} alt="market-research" className="absolute pt-2" />
              <HashLink
                to="/online-trading"
                className="text-xl text-[#26C0B6] pl-10"
                onClick={toggle}
              >
                Online Trading
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Online trading opens doors to global investment
                <p className="text-[#565656]">opportunities</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={CRM} alt="market-research" className="absolute pt-2" />
              <HashLink to="/crm" className="text-xl text-[#26C0B6] pl-10" onClick={toggle}>
                CRM/Dashboard
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Unlocking customer-centric intelligence for
                <p className="text-[#565656]">business growth.</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={devops} alt="market-research" className="absolute pt-2" />
              <HashLink to="/develop" className="text-xl text-[#26C0B6] pl-10" onClick={toggle}>
                DevOps
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Bridging the gap between development and operations
                <p className="text-[#565656]">for seamless collaboration</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={machineLearning} alt="market-research" className="absolute pt-2" />
              <HashLink to="/machine" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Machine Learning</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                It revolutionizes industries with intelligent
                <p className="text-[#565656]"> data-driven solutions</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start my-4">
              <img src={cloud} alt="market-research" className="absolute pt-2" />
              <HashLink to="/cloud" className="text-xl text-[#26C0B6] pl-10" onClick={toggle}>
                Cloud Service
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Empowering businesses with flexible, scalable,
                <p className="text-[#565656]">and innovative solutions.</p>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
