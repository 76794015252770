import React from "react";
import banner from "../../assets/blockchain-banner.svg";
import block from "../../assets/blockchain-ill.svg";
import block1 from "../../assets/blockchain-ill2.svg";
import block2 from "../../assets/blockchain-ill3.svg";

const Blockchain = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Blockchain Technology
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                A method of storing data called blockchain makes it difficult or impossible for the
                system to be changed, hacked, or used improperly. A distributed ledger known as a
                blockchain distributes and copies transactions throughout the network of computers
                involved.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-2 whitespace-pre-wrap">
                {" "}
                Blockchain technology is a framework for storing public transactional records
                (sometimes referred to as &quot;blocks&quot;) across multiple databases in a network
                connected by peer-to-peer nodes. The term &quot;digital ledger&quot; is widely used
                to describe this kind of storage.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">Why is Blockchain popular?</p>
                  <p className="">
                    In terms of technology, blockchain is a digital ledger that has recently
                    attracted a lot of attention. But why has it gathered such a following? So
                    let&apos;s look into it to completely grasp the concept.
                  </p>
                  <p className="mt-2">
                    Recording data and transactions is a crucial part of the business. It costs the
                    company time, money, or both when this information is handled internally or
                    delivered through a third party, such as brokers, bankers, or lawyers.
                    Thankfully, Blockchain eliminates this time-consuming procedure and expedites
                    the transaction, saving both time and money.
                  </p>
                  <p className="mt-2">
                    Contrary to popular belief, it is not possible to alternately utilise Bitcoin
                    and Blockchain. Bitcoin, on the other hand, is a money whose security rests on
                    Blockchain technology. A variety of applications relating to numerous
                    industries, including finance, supply chains, manufacturing, etc., can be made
                    possible by blockchain technology.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={block} alt="work-content" className="mt-5" />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={block1} alt="work-content" className="mt-5" />
              </div>
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black mt-8">
                    What are the types of Blockchains?
                  </p>
                  <p className="mt-4">There are four types of Blockchains:</p>
                  <ul className="pl-4">
                    <li className="list-disc mt-2">
                      <b>Private Blockchain Network: </b>Private blockchains operate effectively for
                      private businesses and organisations on restricted networks. Businesses can
                      customise network characteristics, access and authorization options, and other
                      essential security aspects using private blockchains. A private blockchain
                      network is managed by a solitary organisation.
                    </li>
                    <li className="list-disc mt-2">
                      <b>Public Blockchain Network: </b>Public Blockchain Network Additionally,
                      public blockchains, which served as the foundation for Bitcoin and other
                      cryptocurrencies, aided in the promotion of distributed ledger technology
                      (DLT). Public blockchains also assist in addressing a number of challenges and
                      issues, such as centralization and security flaws.Using DLT, data is dispersed
                      across a peer-to-peer network as opposed to being retained in one location. A
                      consensus method verifies the accuracy of information; proof of stake (PoS)
                      and proof of work (PoW) are two common consensus algorithms.
                    </li>
                    <li className="list-disc mt-2">
                      <b>Premissioned Blockchain Network: </b>Private blockchains with permitted
                      users only are known as permissioned blockchain networks, also known as hybrid
                      blockchains. Businesses regularly create these kinds of blockchains to get the
                      best of both worlds. When deciding who can join the network and in what
                      transactions, they offer superior structure.e better structure when
                      determining who can join the network and in what transactions.
                    </li>
                    <li className="list-disc mt-2">
                      <b>Consortium Blockchain Network</b>Consortial blockchains have both public
                      and private components, similar to permissioned blockchains, however a single
                      consortium blockchain network will be run by a number of different businesses.
                      These blockchains can provide greater security once operational, but being
                      initially more difficult to set up. Blockchain consortiums are the ideal for
                      collaborating with different businesses.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start mt-5">
                  <p className="text-2xl font-bold text-black">Advantages Of Blockchain</p>
                  <p className="mt-4">
                    One of the key benefits of blockchain technology is that it can secure and
                    protect sensitive data from online transactions due to its high level of
                    security. For those that want them, blockchain technology also enables speedy
                    and convenient transactions. In contrast to conventional transaction processes,
                    which can take several days to complete, it just takes a few minutes. Many users
                    also find it advantageous that there is no intervention from governmental or
                    financial third parties.
                  </p>
                  <p className="text-2xl font-bold text-black mt-8">Disadvantage Of Blockchain</p>
                  <p className="mt-4">
                    Public and private keys are utilised in both cryptography and blockchain, and it
                    has been reported that private keys have occasionally resulted in issues.
                    Blockchains have the drawback of putting a user in a difficult situation if they
                    misplace their private key. The scaling limitations caused by the per-node
                    transaction cap are still another drawback. Another drawback is the scaling
                    restrictions brought on by the per-node transaction cap. As a result, completing
                    many transactions and other chores can take several hours.The difficulty of
                    adding or changing information once it has been recorded is another significant
                    flaw with blockchain technology.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={block2} alt="work-content" className="mb-10 mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blockchain;
