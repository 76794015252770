import React from "react";
import { HashLink } from "react-router-hash-link";
import ApplyForm from "../ApplyForm";

const QualityAssuranceApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Quality Assurance
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold">Job Description</h2>
          <p className="text-start mt-2">
            The Quality Assurance (QA) position plays a critical role in ensuring the delivery of
            high-quality products or services to customers. The QA professional is responsible for
            monitoring and evaluating various processes, systems, and products to identify and
            address any potential defects, inconsistencies, or non-compliance issues. They
            collaborate closely with cross-functional teams, including development, production, and
            customer support, to maintain and improve product quality standards. The QA role
            requires a meticulous and detail-oriented approach, with a focus on continuous
            improvement and adherence to industry best practices.
          </p>
          <h2 className="text-start text-2xl font-bold mt-6">Key Responsibilities</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Develop and implement quality assurance procedures, policies, and standards.
            </li>
            <li className="mt-2">
              Conduct inspections, audits, and reviews of processes, products, and systems to
              identify areas of improvement.
            </li>
            <li className="mt-2">
              Design and execute test plans, test cases, and test scripts to ensure product
              functionality and reliability.
            </li>
            <li className="mt-2">
              Collaborate with product managers, engineers, and designers to understand product
              requirements and provide feedback during the development lifecycle.
            </li>
            <li className="mt-2">
              Analyze and interpret data, metrics, and quality indicators to identify trends and
              patterns.
            </li>
            <li className="mt-2">
              Document and communicate identified issues, risks, and opportunities for improvement
              to relevant stakeholders.
            </li>
            <li className="mt-2">
              Coordinate and participate in root cause analysis and corrective action processes.
            </li>
            <li className="mt-2">
              Monitor and track quality performance indicators to drive continuous improvement
              initiatives.
            </li>
            <li className="mt-2">
              Stay updated with industry standards, regulations, and best practices related to
              quality assurance.
            </li>
            <li className="mt-2">
              Provide training and support to team members on quality assurance processes and
              methodologies.
            </li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Requirements</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Bachelor&apos;s degree in a relevant field such as engineering, computer science, or a
              related discipline.
            </li>
            <li className="mt-2">
              Proven work experience in quality assurance or a related field.
            </li>
            <li className="mt-2">
              Strong understanding of quality assurance methodologies, tools, and best practices.
            </li>
            <li className="mt-2">
              Familiarity with software development lifecycle (SDLC) and agile development
              processes.
            </li>
            <li className="mt-2">
              Excellent analytical and problem-solving skills, with the ability to think critically
              and attention to detail.
            </li>
            <li className="mt-2">
              Proficiency in using quality assurance tools, such as defect tracking systems and test
              management software.
            </li>
            <li className="mt-2">
              Knowledge of regulatory requirements and industry standards relevant to the specific
              domain (e.g., ISO standards, FDA regulations).
            </li>
            <li className="mt-2">
              Strong communication and interpersonal skills to effectively collaborate with
              cross-functional teams.
            </li>
            <li className="mt-2">
              Ability to prioritize tasks and manage time efficiently in a fast-paced environment.
            </li>
            <li className="mt-2">
              Continuous learning mindset and willingness to adapt to evolving technologies and
              methodologies.
            </li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Key Skills</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              <b>Quality Assurance Methodologies:</b> Solid understanding of QA principles,
              practices, and methodologies, such as risk-based testing, regression testing, and
              test-driven development (TDD).
            </li>
            <li className="mt-2">
              <b>Testing and Test Automation:</b> Proficiency in designing and executing test cases,
              as well as using automated testing tools and frameworks.
            </li>
            <li className="mt-2">
              <b>Problem Solving:</b> Ability to identify, analyze, and solve complex problems
              related to product quality and performance.
            </li>
            <li className="mt-2">
              <b>Data Analysis:</b> Strong analytical skills to interpret and analyze data, metrics,
              and quality indicators effectively.
            </li>
            <li className="mt-2">
              <b>Collaboration and Communication:</b> Excellent interpersonal skills to work
              collaboratively with diverse teams and communicate effectively with stakeholders at
              all levels.
            </li>
            <li className="mt-2">
              <b>Attention to Detail:</b> Meticulous approach to detail to identify defects,
              inconsistencies, and non-compliance issues.
            </li>
            <li className="mt-2">
              <b>Time Management:</b> Ability to prioritize tasks, meet deadlines, and manage time
              efficiently in a dynamic work environment.
            </li>
            <li className="mt-2">
              <b>Domain Knowledge:</b> Familiarity with the specific industry or domain of the
              organization (e.g., healthcare, finance, software development) to understand relevant
              quality requirements and regulations.
            </li>
            <li className="mt-2">
              <b>Continuous Improvement:</b> Proactive mindset to drive continuous improvement
              initiatives and implement best practices within the quality assurance process.
            </li>
            <li className="mt-2">
              <b>Adaptability:</b> Willingness to learn new technologies, tools, and methodologies
              as needed in a rapidly evolving industry.
            </li>
          </ul>
          <p className="text-start mt-4">
            <b>Note:</b> The specific requirements and key skills may vary depending on the
            organization and industry.
          </p>
          <div id="application-form">
            <ApplyForm type={"Quality Assurance"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityAssuranceApplyNow;
