import React from "react";
import banner from "../../assets/enterprise-banner.png";
import uiUx from "../../assets/ui-img.svg";

const UiUx = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          UI/UX Services
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="font-sans text-[#484848] text-start leading-relaxed whitespace-pre-wrap">
                    UI/UX (User Interface/User Experience) services are focused on creating visually
                    appealing and user-friendly interfaces for digital products and applications,
                    with the goal of providing excellent user experiences. UI/UX services involve
                    the design and development of interfaces that are visually appealing, easy to
                    use, and intuitive for end-users. These services typically involve a combination
                    of visual design, interaction design, information architecture, and usability
                    testing to create interfaces that are engaging, efficient, and effective.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>User Research: </b>User research involves understanding the needs,
                    preferences, and behaviors of end-users through techniques such as interviews,
                    surveys, and usability testing. This helps in identifying user requirements and
                    preferences and guides the design process to create interfaces that meet user
                    needs.
                  </p>
                  <p className="mt-4">
                    <b>Wire framing and Prototyping: </b>Wire framing and prototyping are techniques
                    used to create visual representations of the interface layout and functionality.
                    Wireframes are basic visual representations of the interface layout, while
                    prototypes are interactive representations that allow users to experience the
                    interface&apos;s functionality before development. These techniques help in
                    visualizing and validating the design concepts and interactions.
                  </p>
                  <p className="mt-4">
                    <b>Visual Design: </b>Visual design involves creating the visual elements of the
                    interface, such as color schemes, typography, icons, and graphics. Visual design
                    focuses on creating a visually appealing and consistent interface that aligns
                    with the brand and target audience, while also ensuring that it is easy to
                    understand and use.
                  </p>
                  <p className="mt-4">
                    <b>Interaction Design: </b>
                    Interaction design involves designing how users interact with the interface,
                    including navigation, input controls, and feedback mechanisms. Interaction
                    design aims to create intuitive and efficient interactions that allow users to
                    easily accomplish their tasks and goals within the interface.
                  </p>
                  <p className="mt-4">
                    <b>Information Architecture: </b>
                    Information architecture involves organizing and structuring the content and
                    information within the interface in a logical and meaningful way. This includes
                    designing the navigation, categorization, and labeling of content to make it
                    easily discoverable and accessible to users.
                  </p>
                  <p className="mt-4">
                    <b>Usability Testing: </b>
                    Usability testing involves evaluating the interface with real users to identify
                    any usability issues or areas for improvement. Usability testing helps in
                    identifying any pain points or barriers in the user experience and provides
                    insights for optimizing the interface design.
                  </p>
                  <p className="mt-4 mb-10">
                    <b>Responsive Design: </b>Responsive design involves designing interfaces that
                    are optimized for different devices and screen sizes, such as desktops, tablets,
                    and mobile devices. Responsive design ensures that the interface is usable and
                    visually appealing across different devices, providing a consistent experience
                    for users regardless of the device they are using.
                  </p>
                </div>
              </div>
              <img src={uiUx} alt="ui-image" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UiUx;
