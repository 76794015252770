import React from "react";
import banner from "../../assets/website-banner.png";
import design from "../../assets/design.svg";

const WebDesign = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Website Design
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="font-sans text-[#484848] text-start leading-relaxed whitespace-pre-wrap">
                    Website design refers to the process of creating the visual layout, user
                    interface (UI), and user experience (UX) of a website. It involves designing the
                    aesthetics, functionality, and usability of a website to create an engaging and
                    effective online presence. Website design typically encompasses various
                    elements, including layout, color schemes, typography, graphics, images,
                    navigation, forms, and interactive elements, among others.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>Visual Design: </b>This involves creating an appealing and visually engaging
                    website using elements such as layout, color schemes, typography, and graphics.
                    Visual design aims to create a consistent and visually pleasing look and feel
                    that reflects the brand identity and resonates with the target audience.
                  </p>
                  <p className="mt-4">
                    <b>User Interface (UI) Design: </b>UI design focuses on creating an intuitive
                    and user-friendly interface that allows users to easily navigate through the
                    website and access its content and features. It involves designing elements such
                    as menus, buttons, forms, and interactive elements in a way that is easy to
                    understand and use
                  </p>
                  <p className="mt-4">
                    <b>User Experience (UX) Design: </b>UX design involves creating a positive and
                    meaningful experience for users as they interact with the website. It aims to
                    understand the needs and expectations of users and design the website in a way
                    that meets those needs, provides value, and encourages engagement and
                    conversions.
                  </p>
                  <p className="mt-4">
                    <b>Responsive Design: </b>With the increasing use of mobile devices, responsive
                    design has become crucial in website design. Responsive design ensures that the
                    website renders well on different devices and screen sizes, including desktops,
                    tablets, and smartphones, providing a consistent and optimized experience for
                    users across devices.
                  </p>
                  <p className="mt-4">
                    <b>Content Design: </b>
                    Content design focuses on presenting the website&apos;s content in a visually
                    appealing and engaging manner. It involves designing the layout, typography, and
                    imagery to effectively communicate the brand&apos;s message and convey
                    information to users in a clear and organized manner.
                  </p>
                  <p className="mt-4">
                    <b>Accessibility: </b>
                    Website design should also consider accessibility to ensure that the website is
                    usable and navigable by people with disabilities. This may involve incorporating
                    features such as alt text for images, keyboard accessibility, and other
                    accessibility best practices to ensure inclusive access to the website&apos;s
                    content and features.
                  </p>
                  <p className="mt-6 mb-10">
                    Website design can be done using various tools and technologies, including
                    graphic design software, web design frameworks, content management systems
                    (CMS), and coding languages such as HTML, CSS, and JavaScript. It is important
                    to consider the target audience, business objectives, branding, and user
                    experience when designing a website to create an effective and engaging online
                    presence.
                  </p>
                </div>
              </div>
              <img src={design} alt="design-img" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDesign;
