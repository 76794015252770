import React from "react";
import { HashLink } from "react-router-hash-link";
import digitalSurvey from "../../assets/Digital-Survey.svg";
import surveyBanner from "../../assets/dig-sur.png";
import newsLetter from "../../assets/Subscribe-newsletter.svg";
import bussinessPeople from "../../assets/busi-people.png";
import groupPic from "../../assets/grp-pic.png";
import mediumShot from "../../assets/med-shot.png";

const DigitalReadMore = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="w-full flex items-center justify-center">
            <img className="h-auto w-full" src={surveyBanner} />
          </div>
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-8/12 content-center">
              <h1 className="text-4xl md:text-7xl font-bold text-center leading-relaxed">
                Digital Survey
              </h1>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-6 whitespace-pre-wrap">
                {" "}
                The art and science of applying digital or electronic methods to determine the
                terrestrial, that is the three-dimensional positions of points and the distances and
                angles between them.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-10/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-7/12">
                <h2 className="text-3xl mt-4 text-start font-bold mb-2">
                  Advantages of Digital Survey
                </h2>
                <div className="flex justify-center my-4">
                  <img src={digitalSurvey} alt="" />
                </div>
                <h2 className="text-2xl mt-6 text-start font-bold">Faster</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Digital surveys take, on average, two-thirds less time to complete than
                  traditional research methods. You don&apos;t have to wait for paper questionnaires
                  to be returned because the information is being gathered automatically; rather,
                  responses are given virtually instantly. Digital marketing specialists claim that
                  within the first three days of the study project, more than half of the responses
                  are obtained.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Cheaper</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Your research expenditures will be reduced by using Digital questionnaires. You
                  won&apos;t have to spend time or resources entering the data into a database,
                  which will save you money on mailing. The findings are always available and
                  responses are processed automatically.
                </p>
                <h2 className="text-2xl mt-4 text-start font-bold">Accurate</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Digital surveys have a far lower margin of error since respondents input their
                  answers directly into the database. Since human error can occur whenever someone
                  has to execute a repetitive operation, traditional solutions rely on personnel to
                  input all information accurately.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Analyzed Quickly</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Digital surveys have a far lower margin of error since respondents input their
                  answers directly into the database. Since human error can occur whenever someone
                  has to execute a repetitive operation, traditional solutions rely on personnel to
                  input all information accurately.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Easy to use for participants</h2>
                <p className="text-start text-black pb-4 mt-2">
                  The vast majority of people who have access to the Internet prefer to respond to
                  surveys digitally rather than by phone. Participants can complete a Digital survey
                  whenever it is most convenient for them, and it takes much less time. The
                  SmartSurvey Skip logic feature allows you to automatically skip questions that are
                  irrelevant to a specific participant.
                </p>
                <h2 className="text-2xl mt-4 text-start font-bold">Easy to Style</h2>
                <p className="text-start text-black pb-4 mt-2">
                  A Digital survey is an opportunity to reinforce your brand and remind users of the
                  advantages you offer. An online survey can be tailored to match your business
                  website with unique backgrounds, graphics, logos, fonts, final redirect pages, and
                  even the survey URL. Make sure your Digital survey provider offers
                  mobile-responsive surveys so you may expand your audience while keeping your brand
                  consistent across all platforms.
                </p>
                <h2 className="text-2xl mt-4 text-start font-bold">More Honest</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Market researchers have discovered that respondents almost universally favor
                  completing Digital surveys over participating in printed questionnaires or
                  telephone interviews and typically give longer and more in-depth responses. People
                  are more likely to reply honestly to surveys when they are designed and sent in a
                  relevant and focused manner.
                </p>
                <h2 className="text-2xl mt-4 text-start font-bold">More Selective</h2>
                <p className="text-start text-black pb-4 mt-2">
                  With a Digital survey, you may pre-screen respondents and restrict survey
                  participation to those who fit your target demographic. With the help of Smart
                  Survey&apos;s Live Group service, you may locate survey respondents and target a
                  particular target audience or demographic with pertinent questions that will apply
                  to them.
                </p>
                <h2 className="text-2xl mt-4 text-start font-bold">More Flexible</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Depending on the response to a previous question, the order of the questions in a
                  Digital survey can be modified or questions might be skipped entirely. In this
                  manner, a survey can be customized as each respondent completes it.
                </p>
                <h2 className="text-4xl mt-4 text-start font-bold">Conclusion</h2>
                <p className="text-start text-black pb-4 mt-3">
                  In conclusion, online surveys are a fantastic alternative for individuals and
                  organizations who want to do their own research because they take less time, are
                  less expensive, produce results faster, and can be transferred and used in a
                  variety of ways to address significant issues.
                </p>
              </div>
              <div className="sticky top-24 ml-auto bg-[#53BCAC] h-[550px] sm:w-[200px] md:w-[250px] lg:w-[350px] rounded-3xl p-6">
                <p className="text-white text-2xl text-justify">
                  <span className="text-[#90F5E6]">Subscribe</span> Newsletter
                </p>
                <p className="text-white pt-2 text-justify">
                  Get updated with our weekly blogs and expand your technical knowledge with our
                  in-depth content
                </p>
                <div className="relative mt-6">
                  <input
                    type="search"
                    className="block h-11 w-full p-4 text-sm outline-none text-gray-900 border border-gray-500 rounded-full bg-white dark:placeholder-gray-500 text-black"
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 h-11 bg-orange-500 focus:ring-4 focus:outline-none font-medium rounded-full text-md px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <div className="">
                  <img className="ml-auto pt-16 sticky flex justify-center" src={newsLetter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <h3 className="text-5xl text-[#53BCAC] font-bold">Related Blog</h3>
          <p className="text-gray-400 mt-2">
            Explore this space to stay tuned to our latest blog post.
          </p>
          <div>
            <div>
              <div
                id="blog-card"
                className="flex flex-wrap items-center w-full mt-5 justify-evenly md:mt-10 m-auto"
              >
                <div className="flex flex-row items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={bussinessPeople} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        Work in CRM
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        A customer relationship management (CRM) platform is a piece of software
                        businesses use to control contacts with clients, keep records of their
                        information, and automate various steps in the client&apos;s...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/crm/#crm"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={mediumShot} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
                        Latest Technology
                      </h2>

                      <p className="text-sm text-gray-600 p-3">
                        {" "}
                        In the era of advanced technology, it is necessary to remain updated with
                        the trending technology. Pando India Software Consultants is one of them
                        where the latest technologies are prominently used.
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/latest-technology"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-8"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center gap-10 justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={groupPic} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        5th Anniversary of Pando India Software Consultants
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        From the beginning, Pando India Software Consultant has been laying a solid
                        foundation. As PISC completes its five magnificent and wonderful years in
                        December 2022...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/anniversary"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-4"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalReadMore;
