import React from "react";
import banner from "../../assets/financial-banner.png";
import fss from "../../assets/fss.svg";

const Financial = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Financial Software Services
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="font-sans text-[#484848] text-start leading-relaxed whitespace-pre-wrap">
                    Software services in financial software refer to cloud-based or web-based
                    applications that are designed to cater to the unique needs and requirements of
                    the financial industry. These services provide functionalities and capabilities
                    that are tailored for managing financial operations, data, and transactions, and
                    they are used by financial institutions, such as banks, insurance companies,
                    investment firms, and other financial service providers, to automate and
                    streamline their financial processes, enhance risk management, and ensure
                    compliance with regulatory requirements.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>Accounting Software: </b>Accounting software is designed to manage financial
                    transactions, recordkeeping, and reporting for businesses and organizations. It
                    typically includes features for general ledger management, accounts payable,
                    accounts receivable, financial reporting, and tax management. Accounting
                    software helps businesses and financial professionals accurately track and
                    manage their financial transactions, generate financial reports, and ensure
                    compliance with accounting standards.
                  </p>
                  <p className="mt-4">
                    <b>Financial Management Software: </b>Financial management software provides
                    comprehensive tools and capabilities for managing an organization&apos;s
                    financial operations, including budgeting, financial planning, financial
                    analysis, cash flow management, and financial reporting. This type of software
                    is used by businesses and financial professionals to gain insights into their
                    financial performance, make informed financial decisions, and optimize their
                    financial processes.
                  </p>
                  <p className="mt-4">
                    <b>Trading and Investment Platforms: </b>Trading and investment platforms are
                    used by investment firms, brokerages, and other financial institutions to manage
                    investment portfolios, execute trades, and monitor market trends. These
                    platforms provide real-time market data, trade execution capabilities, portfolio
                    management features, and risk management tools to support trading and investment
                    activities.
                  </p>
                  <p className="mt-4">
                    <b>Risk Management Software: </b>
                    Risk management software is designed to identify, assess, and mitigate risks in
                    financial operations. It provides tools and capabilities for risk assessment,
                    risk modeling, risk analytics, and risk reporting to help financial institutions
                    manage and mitigate risks associated with investments, transactions, compliance,
                    and other financial activities.
                  </p>
                  <p className="mt-4">
                    <b> Payment Processing Software: </b>
                    Payment processing software enables businesses and financial institutions to
                    process payments, including credit card transactions, electronic fund transfers,
                    and other payment methods. These software services typically include features
                    for payment processing, transaction management, fraud detection, and payment
                    reconciliation.
                  </p>
                  <p className="mt-4">
                    <b>Compliance and Regulatory Software: </b>
                    Compliance and regulatory software helps financial institutions ensure
                    compliance with various regulatory requirements, including anti-money laundering
                    (AML), know-your-customer (KYC), and other financial regulations. These software
                    services provide features for data management, risk assessment, monitoring, and
                    reporting to support compliance and regulatory obligations.
                  </p>
                  <p className="mt-4 mb-10">
                    <b>Financial Reporting and Analytics Software: </b>Financial reporting and
                    analytics software provides tools and capabilities for generating financial
                    reports, analyzing financial data, and gaining insights into financial
                    performance. These software services typically include features for financial
                    data visualization, financial statement analysis, financial forecasting, and
                    financial performance metrics.
                  </p>
                  <p className="mt-4 mb-10">
                    Financial software services are typically highly regulated and require robust
                    security measures to protect sensitive financial data. They are used by
                    financial institutions to automate and streamline their financial operations,
                    enhance risk management, ensure compliance with regulatory requirements, and
                    make informed financial decisions.
                  </p>
                </div>
              </div>
              <img src={fss} alt="financial-image" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financial;
