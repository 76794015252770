import Blog4 from "../../assets/Blog4.png";
import Blog5 from "../../assets/Blog5.png";
import Blog6 from "../../assets/Blog6.png";

export const RelatedBlog = () => {
  return (
    <div>
      <div className="Heading mx-16">
        <h1 className="text-teal-300 text-4xl py-2 font-bold">Related Blog</h1>
        <p className="text-gray-400 text-lg tracking-wider">
          Explore this space to stay tuned to our latest blog post.
        </p>
      </div>
      <div className="relatemain">
        <div>
          {" "}
          <div className="Relatedcard">
            <div>
              <img className="relatedimg" src={Blog4} />
              <div className="relatedName ">
                <h2 className="relatedText">25 Nov 2022</h2>
                <div className="flex ">
                  <span>
                    <h2 className="relatedText">Nikit SIngh </h2>
                  </span>
                </div>
              </div>
              <h2 className="related_h1">
                Startupsthat are shaping ourlives <br /> every day
              </h2>
              <p className="relatedpera">
                We understand your business requirements and transform them with the right mobile
                app solutions by putting your customer experience in the center of design – And we
                do that with Scalability, Speed & Security.
              </p>
              <button className="relatedBtn">Submit</button>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div className="Relatedcard">
            <div>
              <img className="relatedimg " src={Blog5} />
              <div className="relatedName">
                <h2 className="relatedText">25 Nov 2022</h2>
                <div className="flex ">
                  <span>
                    <h2 className="relatedText">Nikit SIngh </h2>
                  </span>
                </div>
              </div>
              <h2 className="text-gray-400 font-bold text-md">
                Startupsthat are shaping ourlives <br /> every day
              </h2>
              <p className="relatedpera">
                We understand your business requirements and transform them with the right mobile
                app solutions by putting your customer experience in the center of design – And we
                do that with Scalability, Speed & Security.
              </p>
              <button className="relatedBtn">Submit</button>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div className="Relatedcard">
            <div>
              <img className="relatedimg" src={Blog6} />
              <div className="relatedName ">
                <h2 className="relatedText">25 Nov 2022</h2>
                <div className="flex">
                  <span>
                    <h2 className="relatedText">Nikit SIngh </h2>
                  </span>
                </div>
              </div>
              <h2 className="related_h1">
                Startupsthat are shaping ourlives <br /> every day
              </h2>
              <p className="relatedpera">
                We understand your business requirements and transform them with the right mobile
                app solutions by putting your customer experience in the center of design – And we
                do that with Scalability, Speed & Security.
              </p>
              <button className="relatedBtn">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
