import React from "react";
import banner from "../../assets/software-tool-banner.png";
import software from "../../assets/software-ill.png";
import softwareImage from "../../assets/software-ill2.png";

export const SoftwareTools = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banking" />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Software Tools
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                The correct development tool is essential in the quickly evolving field of software
                development. The top software development tools will typically have excellent sprint
                management, cutting-edge bug, and problem-tracking features, automation at their
                core, and seamless collaboration. Thankfully, the market today is flooded with
                software development tools. Choosing the right software development tools to add to
                your team&apos;s tech stack can be difficult.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed whitespace-pre-wrap">
                {" "}
                You can ease the pain of selecting development tools using today&apos;s guide. You
                can rapidly review all the essential characteristics, benefits, and drawbacks of the
                most widely used agile software development platforms in the manual.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">Software Development Tools</p>
                  <p className="">
                    Software development tools are computer programs utilized by software
                    development teams for the design, coding, management, and maintenance of
                    applications, frameworks, systems, and other programs. These devices are also
                    frequently known as software programming devices.
                  </p>
                  <p className="font-bold pt-4">
                    Software development tools include, for instance:
                  </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">Linkers</li>
                    <li className="list-disc mt-4">Coding editors</li>
                    <li className="list-disc mt-4">GUI creators</li>
                    <li className="list-disc mt-4">Tools for performance analysis</li>
                    <li className="list-disc mt-4">Assemblers \sCompilers</li>
                  </ul>
                  <p className="mt-4">
                    Sometimes a single tool can serve many purposes. For instance, one tool might
                    serve as a compiler, performance analysis tool, and code editor. But, in some
                    circumstances, you might need to buy many tools, one for each purpose.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={software} alt="software-image" className="mb-5 mt-10" />
              </div>
            </div>
          </div>
          <div className="text-start">
            <p className="text-2xl font-bold text-black mt-4">
              Some best Software Development tools{" "}
            </p>
            <ul className="pl-4">
              <li className="list-disc mt-6 font-bold">Github</li>
              <p className="pt-2">
                One of the top software development tools is GitHub. It is a cloud-based software
                development platform that gives programmers the ability to manage software projects
                and exchange and evaluate code. This tool&apos;s wide-ranging community support and
                integration with Git&apos;s version control features contribute to its popularity.
                Software developers also have the option of saving their projects as private or
                public.
              </p>
              <h2 className="font-bold mt-4">Features of Github</h2>
              <ul className="list-disc ml-4">
                <li className="mt-3">
                  Support for iteration that gets rid of repetition and errors.
                </li>
                <li className="mt-3">
                  Automation, complex search, and command running capabilities are made possible by
                  the command palette navigation control.
                </li>
                <li className="mt-3">
                  Instruments for project management to coordinate code review.
                </li>
                <li className="mt-3">
                  Simple documentation for all software development projects.
                </li>
                <li className="mt-3">
                  Enables developers to directly host documents from repositories.
                </li>
              </ul>
              <li className="list-disc mt-6 font-bold">Atom</li>
              <p className="pt-2">
                Atom is a platform, environment, and integrated tool for developing software.
                Because of its extensive third-party connectors and powerful customizability
                features, developers frequently select Atom. Moreover, practically all widely used
                programming frameworks and languages are supported by Atom.
              </p>
              <h2 className="font-bold mt-4">Features of Atom</h2>
              <ul className="list-disc ml-4">
                <li className="mt-3">
                  Several projects can be opened or browsed in a single window.
                </li>
                <li className="mt-3">
                  Split the Atom interface into panes to compare code from different files.
                </li>
                <li className="mt-3">Intelligent and adaptable autocomplete</li>
                <li className="mt-3">All main operating systems are compatible with Atom.</li>
              </ul>
              <li className="list-disc mt-6 font-bold">Cloud 9</li>
              <p className="pt-2">
                You may write, run, and debug code directly from your browser using Amazon&apos;s
                Cloud 9 IDE. Three essential features are included in this software development
                tool: a code editor, a terminal, and a debugger (from its integrated development
                environment).
              </p>
              <h2 className="font-bold mt-4">Features of Cloud 9</h2>
              <ul className="list-disc ml-4">
                <li className="mt-3">
                  Essential tool for most common programming languages in software development
                </li>
                <li className="mt-3">
                  Enables simple switching between running serverless applications locally and
                  remotely.
                </li>
                <li className="mt-3">
                  Software development teams can share projects, track changes, and pair programs in
                  real-time thanks to collaboration functionality.
                </li>
                <li className="mt-3">Command line interface with built-in authentication</li>
              </ul>
            </ul>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <ul className="list-disc ml-4">
                    <li className="font-bold text-black">Crimson</li>
                    <p className="mt-2">
                      Red Lion is the owner and manager of the programming environment Crimson. For
                      the development of &quot;G3, G3 Kadet, and Graphite HMI operator panels,
                      Graphite Edge, and Core Controllers,&quot; it offers strong drag-and-drop
                      setup, display, and data tools. The purpose of this programming tool is to
                      explicitly benefit from other Red Lion capabilities.
                    </p>
                    <h2 className="font-bold mt-4">Features of Crimson</h2>
                    <ul className="list-disc ml-4">
                      <li className="mt-3">
                        A vast collection of more than 5000 graphics organized into more than 60
                        categories
                      </li>
                      <li className="mt-3">Access to several Red Lion features directly</li>
                      <li className="mt-3">A programming environment using C syntax</li>
                      <li className="mt-3">Linguistic versatility</li>
                    </ul>
                  </ul>
                  <ul className="list-disc ml-4">
                    <li className="font-bold text-black mt-6">Bootstrap</li>
                    <p className="mt-2">
                      Bootstrap is one of the top responsive frameworks to employ for developers
                      that work with HTML, Java, and CSS. This front-end toolkit is feature-rich and
                      extensible to make front-end development easier.
                    </p>
                    <h2 className="font-bold mt-4">Features of Bootstrap</h2>
                    <ul className="list-disc ml-4">
                      <li className="mt-3">Prepared code blocks to speed up development</li>
                      <li className="mt-3">A lengthy list of elements</li>
                      <li className="mt-3">
                        HTML drag-and-drop functionality&apos;s fundamental styling
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={softwareImage} alt="software-image" className="mb-5 mt-10" />
              </div>
            </div>
          </div>
          <div className="text-start mt-6">
            <ul className="list-disc ml-4">
              <li className="font-bold text-black mt-6">UltraEdit</li>
              <p className="mt-2">
                Powerful code editors for Mac, Linux, and Windows include UltraEdit. It can handle
                large files and supports practically all programming languages (up to 10 GB).
                UltraEdit provides project management, text editing, data sorting, and programming
                features that developers can use to streamline the software development process.
              </p>
              <h2 className="font-bold mt-4">Features of UltraEdit</h2>
              <ul className="list-disc pl-4">
                <li className="mt-3">Robust operation and file load</li>
                <li className="mt-3">Customized native FTP themes</li>
                <li className="mt-3">
                  Extraordinary support for XML and JSON Extensive search, replacement, and
                  discovery features
                </li>
                <li className="mt-3">Highlights of syntax for all commonly used languages</li>
              </ul>
            </ul>
            <p className="text-2xl font-bold text-black mt-4">Conclusion</p>
            <p className="mt-2 mb-10">
              Software development involves a lot, therefore having the correct tool to carry the
              load for your team will be helpful. Understandably, you want a tool that is
              user-friendly but loaded with features as a software developer to speed up the
              software development process. The majority of the software development tools included
              in this review guide are capable of filling this need.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
