import React from "react";
import { HashLink } from "react-router-hash-link";
import dropCV from "../../assets/drop-cv.svg";
import ApplyForm from "../ApplyForm";

const Python = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Python Developer
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold">Job brief</h2>
          <p className="text-start mt-2">
            For python developer We are seeking a talented and experienced Python Developer to join
            our team. As a Python Developer, you will be responsible for developing high-quality
            software solutions, contributing to the full software development lifecycle, and
            collaborating with cross-functional teams to deliver innovative products. Your primary
            focus will be on designing, developing, and maintaining Python applications, so a strong
            background in Python programming is essential.
          </p>
          <h2 className="text-start text-2xl font-bold mt-6">Responsibilities</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Design, develop, and maintain Python applications that meet the specified requirements
              and project objectives.
            </li>
            <li className="mt-4">
              Write efficient, reusable, and reliable Python code, following best practices and
              coding standards.
            </li>
            <li className="mt-4">
              Collaborate with cross-functional teams, including software engineers, product
              managers, and designers, to understand project requirements and deliver high-quality
              software solutions.
            </li>
            <li className="mt-4">
              Participate in code reviews to ensure code quality, performance, and adherence to
              established standards.
            </li>
            <li className="mt-4">
              Identify and troubleshoot software defects and issues, providing timely resolutions.
            </li>
            <li className="mt-4">
              Stay up-to-date with industry trends and technologies, and continuously improve your
              knowledge and skills to enhance software development practices.
            </li>
            <li className="mt-4">
              Contribute to the software development lifecycle, including requirements gathering,
              design, development, testing, deployment, and maintenance.
            </li>
            <li className="mt-4">
              Collaborate with team members to identify opportunities for process improvements and
              contribute to the development of best practices.
            </li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Key Skills</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">Should have 0-3 years experience</li>
            <li className="mt-4">
              <b>Python Programming:</b> Strong understanding of Python programming language and its
              libraries, with the ability to write efficient, clean, and maintainable code.
            </li>
            <li className="mt-4">
              <b>Software Development Principles:</b> Solid grasp of software development
              principles, including object-oriented programming, data structures, algorithms, and
              design patterns.
            </li>
            <li className="mt-4">
              <b>Python Frameworks:</b> Proficiency in using Python frameworks such as Django,
              Flask, or Pyramid for building web applications.
            </li>
            <li className="mt-4">
              <b>Database Management:</b> Experience working with database systems like PostgreSQL,
              MySQL, or MongoDB, and knowledge of SQL for data manipulation and retrieval.
            </li>
            <li className="mt-4">
              <b>Front-end Technologies:</b> Familiarity with front-end technologies such as HTML,
              CSS, and JavaScript, as well as web development frameworks like React or Angular.
            </li>
            <li className="mt-4">
              <b>Problem-solving:</b> Strong problem-solving skills with the ability to analyze and
              debug code, as well as optimize it for performance and efficiency.
            </li>
            <li className="mt-4">
              <b>Version Control:</b> Proficiency in using version control systems like Git to
              manage source code and collaborate with other developers.
            </li>
            <li className="mt-4">
              <b>Agile Methodologies:</b> Familiarity with agile software development methodologies,
              including iterative development, continuous integration, and test-driven development.
            </li>
            <li className="mt-4">
              <b>Communication and Collaboration:</b> Excellent communication skills, both verbal
              and written, with the ability to collaborate effectively with technical and
              non-technical team members.
            </li>
            <li className="mt-4">
              <b>Attention to Detail:</b> Keen attention to detail and a commitment to delivering
              high-quality software solutions within given deadlines.
            </li>
          </ul>
          <p className="mt-5 text-start">
            <b>Note:</b>These responsibilities and key skills can be adjusted and tailored to fit
            the specific requirements and preferences of your organization and the Python developer
            role you are looking to fill.
          </p>
          <div id="application-form">
            <ApplyForm type={"Python"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Python;
