import React from "react";
import { HashLink } from "react-router-hash-link";
import bussinessPeople from "../../assets/busi-people.png";
import mediumShot from "../../assets/med-shot.png";
import digitalSurvey from "../../assets/digital-sur.png";

export const BlogDes = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="">
      <div
        id="blog-card"
        className="flex flex-wrap items-center w-full mt-5 justify-evenly md:mt-10"
      >
        <div className="transition-all m-5 duration-300 transform bg-[#f7faf9] shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
          <img src={bussinessPeople} alt="" className="rounded-3xl w-full p-2 h-56" />
          <div className="text-center">
            <h2 className="flex justify-center text-lg font-bold text-gray-600 ">Work in CRM</h2>
            <p className="text-sm text-gray-600 before:text-black-500"> </p>

            <p className="text-sm text-gray-600 p-3">
              {" "}
              A customer relationship management (CRM) platform is a piece of software businesses
              use to control contacts with clients, keep records of their information, and automate
              various steps in the client&apos;s...
            </p>
          </div>
          <div className="flex justify-center">
            <HashLink
              role="button"
              smooth
              to="/crm/#crm"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10 pl-3"
            >
              Read More
            </HashLink>
          </div>
        </div>{" "}
        <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
          <img src={mediumShot} alt="" className="rounded-3xl w-full p-2 h-56" />
          <div className="text-center">
            <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
              Latest Technologies
            </h2>
            <p className="text-sm text-gray-600 p-3">
              {" "}
              In the era of advanced technology, it is necessary to remain updated with the trending
              technology. Pando India Software Consultants is one of them where the latest
              technologies are prominently used.
            </p>
          </div>
          <div className="flex justify-center">
            <HashLink
              role="button"
              smooth
              to="/latest-technology"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 pl-3 m-8"
            >
              Read More
            </HashLink>
          </div>
        </div>{" "}
        <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
          <img src={digitalSurvey} alt="" className="rounded-3xl w-full p-2 h-56" />
          <div className="text-center">
            <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">Digital Survey</h2>

            <p className="text-sm text-gray-600 p-3">
              {" "}
              The art and science of applying digital or electronic methods to determine the
              terrestrial, that is the three-dimensional positions of points and the distances and
              angles between them...
            </p>
          </div>
          <div className="flex justify-center">
            <HashLink
              role="button"
              smooth
              to="/digital"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 pl-3 m-8"
            >
              Read More
            </HashLink>
          </div>
        </div>{" "}
      </div>
      <div className="relative flex justify-center mb-20 font-sans text-center transition ease-in-out delay-150 bg-cray-300">
        <HashLink
          role="button"
          onClick={handleClick}
          to="/blog/#blog"
          className="bg-orange-500 w-[200px] rounded-md font-medium my-4 py-3 px-5 text-white"
        >
          View More
        </HashLink>
      </div>
    </div>
  );
};
