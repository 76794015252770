import Card1 from "../../assets/Card/Card1.png";
import Card2 from "../../assets/Card/Card2.png";
import Programmer from "../../assets/Programmer.png";
import Card4 from "../../assets/Card/Card4.png";
import Card5 from "../../assets/Card/Card5.png";
import Card6 from "../../assets/Card/Card6.png";
import Card7 from "../../assets/Card/Card7.png";
import Card8 from "../../assets/Card/Card8.png";
import { HashLink } from "react-router-hash-link";

export function Card() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-center gap-20 md:w-full md:mt-20">
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="md:h-40 md:w-80 rounded-xl w-full" src={Card1} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              Market Research
            </h2>
            <p className="text-sm text-gray-600">
              {" "}
              By meticulously gathering information on people, firms, or both, market research aims
              to better understand the needs of a market.
            </p>
          </div>
          <div className="flex justify-center m-2">
            <HashLink
              to="/market-research"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Card2} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              Block Chain technology
            </h2>
            <p className="text-sm text-gray-600">
              {" "}
              A method of storing data called blockchain makes it difficult or impossible for the
              system to be changed, hacked, or used improperly.
            </p>
          </div>
          <div className="flex justify-center m-2">
            <HashLink
              to="/block-chain"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Programmer} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              Software Development
            </h2>
            <p className="text-sm text-gray-600">
              {" "}
              Computer programmers write programmes using a process called software development.The
              procedure, commonly referred to as the Software Development.
            </p>
          </div>
          <div className="flex justify-center m-2">
            <HashLink
              to="/software-development"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Card4} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              Online Trading
            </h2>
            <p className="text-sm text-gray-600 ">
              {" "}
              Online trading has greatly simplified and expedited the process of purchasing and
              selling shares. Trading can now be done instantly...
            </p>
          </div>
          <div className="flex justify-center m-4">
            <HashLink
              to="/online-trading"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center w-full gap-20 mt-16">
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Card5} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              CRM/Dashboard
            </h2>
            <p className="text-sm text-gray-600 ">
              {" "}
              The best CRM software includes dashboards as a crucial feature. Quick snapshots of
              your sales activities and important performance...
            </p>
          </div>
          <div className="flex justify-center">
            <HashLink
              to="/crm"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Card6} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">DevOps</h2>
            <p className="text-sm text-gray-600">
              {" "}
              The terms &quot;development&quot; and &quot;operations&quot; are combined to generate
              the term &quot;DevOps,&quot; which describes a collaborative or shared approach...
            </p>
          </div>
          <div className="flex justify-center">
            <HashLink
              to="/develop"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Card7} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              Machine Learning
            </h2>
            <p className="text-sm text-gray-600">
              {" "}
              Machine learning, a cutting-edge field of research, enables computers to learn on
              their own using past data. Machine learning makes predictions...
            </p>
          </div>
          <div className="flex justify-center">
            <HashLink
              to="/machine"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
        <div className="transition-all duration-300 transform bg-white shadow-lg w-60 rounded-xl">
          <img className="object-cover md:h-40 md:w-80 rounded-xl" src={Card8} />
          <div className="p-2">
            <h2 className="flex justify-center mb-2 text-lg font-bold text-teal-300 ">
              Cloud Service
            </h2>
            <p className="text-sm text-gray-600">
              {" "}
              The term &rdquo;cloud services&rdquo; refers to a wide range of services that are made
              readily available to customers and organizations online.
            </p>
          </div>
          <div className="flex justify-center m-4">
            <HashLink
              to="/cloud"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Read More
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}
