import React from "react";
import { HashLink } from "react-router-hash-link";
import dropCV from "../../assets/drop-cv.svg";
import ApplyForm from "../ApplyForm";

const ClientServiceApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Client Service Manager
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold">Job Summary</h2>
          <p className="text-start mt-2">
            The Client Success Manager works with Zamplia’s API-based partners through the
            establishment of strategic relationships with new and existing users, setting critical
            performance goals, and then acting as a consultative resource to help partners achieve
            their goals. The Manager is also responsible for training and onboarding new users,
            evaluating partner growth strategies, generating usage reports, and maximising up sell
            opportunities by engaging with Sales, Solutions, Product Development, Product
            Management, Support, and Training as necessary.
          </p>
          <h2 className="text-start text-2xl font-bold mt-6">
            Essential Functions and Responsibilities
          </h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Enable partners or customers to achieve their goals through the use of the Marketplace
            </li>
            <li className="mt-2">
              Manage relationships with clients to drive high-impact business strategies enabled by
              our products, and advocate for customer needs across internal stakeholder groups
            </li>
            <li className="mt-2">
              Execute client success plans to optimize client’s use of Marketplace{" "}
            </li>
            <li className="mt-2">
              Support onboarding new client business and collaborate with internal stakeholders to
              develop working integrations for new clients
            </li>
            <li className="mt-2">
              Gain a working understanding of clients’ businesses and organizational structures to
              identify and execute opportunities for existing clients
            </li>
            <li className="mt-2">
              Work closely with sales and technical colleagues to pitch new products and provide
              strategic consultation as a client advocate
            </li>
            <li className="mt-2">
              Analyze trends in client performance on a regular basis and form proactive
              recommendations
            </li>
            <li className="mt-2">
              Develop a full understanding of the company, the market research industry, and how our
              marketplace technology can solve challenges
            </li>
            <li className="mt-2">
              Suggest, standardize, and scale policies, procedures and best practices that improve
              team operations
            </li>
            <li className="mt-2">Other duties as assigned.</li>
            <li className="mt-2">
              Position and responsibilities to evolve with time and progression.
            </li>
            <li className="mt-2">A good understanding of software and technology solutions</li>
            <li className="mt-2">
              Ability to convey complicated ideas to technical and nontechnical audiences
            </li>
            <li className="mt-2">Exposure to API integrations and customized applications</li>
            <li className="mt-2">
              Previous experience working with Salesforce, PowerBI and/or Google Suite{" "}
            </li>
            <li className="mt-2">Market research or Sample experience an asset</li>
            <li className="mt-2">
              An ongoing focus on excellent client service via polished communications
            </li>
            <li className="mt-2">
              Ability to establish priorities and proceed with objectives with a high degree of
              quality without supervision
            </li>
          </ul>
          <div id="application-form">
            <ApplyForm type={"Client Service Manager"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientServiceApplyNow;
