import React from "react";
import { HashLink } from "react-router-hash-link";
import blogBanner from "../../assets/Our-blog-banner.svg";
import bussinessPeople from "../../assets/busi-people.png";
import mediumShot from "../../assets/med-shot.png";
import digitalSurvey from "../../assets/digital-sur.png";
import groupPic from "../../assets/grp-pic.png";

export const BlogCard = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="mb-8">
      <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
        <div className="absolute md:mt-10 lg:mt-24">
          <div className="lg:w-full md:w-11/12 w-2/4 text-center max-h-fit">
            <p className="text-justify text-transparent bg-clip-text bg-gradient-to-r from-[#53BCAC] to-[#F0F0F0] pl-10 lg:pl-32 md:pl-20 md:h-20 lg:h-28 xl:h-36 text-2xl font-bold text-[#3AB3AD] xl:text-9xl lg:text-8xl md:text-7xl text-2xl">
              Our Blogs
            </p>
            <p className="lg:mt-4 md:mt-5 text-justify w-4.5 md:w-9/12 lg:w-9/12 pl-10 lg:pl-32 md:pl-20 font-sans font-medium text-[#484848] xl:text-2xl lg:text-xl md:text-lg text-xs">
              {" "}
              Discover, Learn and Explore - A Journey Through Knowledge and Ideas
            </p>
          </div>
        </div>
        <div className="lg:w-full">
          <img className="object-cover lg:w-[100%] h-auto" src={blogBanner} />
        </div>
      </div>
      <div>
        <div
          id="blog-card"
          className="flex flex-wrap items-center w-full mt-5 justify-evenly md:mt-10"
        >
          <div className="transition-all m-5 duration-300 transform bg-[#f7faf9] shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
            <img src={bussinessPeople} alt="" className="rounded-3xl w-full p-2 h-56" />
            <div className="text-center">
              <h2 className="flex justify-center text-lg font-bold text-gray-600 ">Work in CRM</h2>
              <p className="text-sm text-gray-600 before:text-black-500"> </p>

              <p className="text-sm text-gray-600 p-3">
                {" "}
                A customer relationship management (CRM) platform is a piece of software businesses
                use to control contacts with clients, keep records of their information, and
                automate various steps in the client&apos;s...
              </p>
            </div>
            <div className="flex justify-center">
              <HashLink
                role="button"
                smooth
                to="/crm/#crm"
                onClick={handleClick}
                className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10 pl-3"
              >
                Read More
              </HashLink>
            </div>
          </div>{" "}
          <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
            <img src={mediumShot} alt="" className="rounded-3xl w-full p-2 h-56" />
            <div className="text-center">
              <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
                Latest Technologies
              </h2>
              <p className="text-sm text-gray-600 p-3">
                {" "}
                In the era of advanced technology, it is necessary to remain updated with the
                trending technology. Pando India Software Consultants is one of them where the
                latest technologies are prominently used.
              </p>
            </div>
            <div className="flex justify-center">
              <HashLink
                role="button"
                smooth
                to="/latest-technology"
                onClick={handleClick}
                className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 pl-3 m-8"
              >
                Read More
              </HashLink>
            </div>
          </div>{" "}
          <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
            <img src={digitalSurvey} alt="" className="rounded-3xl w-full p-2 h-56" />
            <div className="text-center">
              <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
                Digital Survey
              </h2>
              <p className="text-sm text-gray-600 p-3">
                {" "}
                The art and science of applying digital or electronic methods to determine the
                terrestrial, that is the three-dimensional positions of points and the distances and
                angles between them...
              </p>
            </div>
            <div className="flex justify-center">
              <HashLink
                role="button"
                smooth
                to="/digital"
                onClick={handleClick}
                className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 pl-3 m-8"
              >
                Read More
              </HashLink>
            </div>
          </div>{" "}
          {/* Second Card Section */}
          <div className="flex flex-wrap items-center w-full gap-10 justify-evenly mt-5 md:mt-10">
            <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
              <img src={groupPic} alt="" className="rounded-3xl w-full p-2 h-56" />
              <div className="text-center">
                <h2 className="flex justify-center text-lg font-bold text-gray-600">
                  5th Anniversary of Pando India Software Consultants
                </h2>
                <p className="text-sm text-gray-600 before:text-black-500"> </p>

                <p className=" text-sm text-gray-600 p-3">
                  {" "}
                  From the beginning, Pando India Software Consultant has been laying a solid
                  foundation. As PISC completes its five magnificent and wonderful years in December
                  2022...
                </p>
              </div>
              <div className="flex justify-center">
                <HashLink
                  role="button"
                  smooth
                  to="/anniversary"
                  onClick={handleClick}
                  className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 pl-3 m-4"
                >
                  Read More
                </HashLink>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
