import React from "react";
import BlogBg from "../../assets/BlogBg.png";

const EmployeeEngagementReadMore = () => {
  return (
    <div id="employee" className="flex items-center justify-center">
      <div>
        <div className="border  md:w-full">
          <img className="object-cover opacity-80 md:w-full" src={BlogBg} />
          <h3 className="text-6xl font-bold text-teal-300 relative bottom-[400px] text-center ">
            Employee Engagement
          </h3>
        </div>
        <div className="md:mx-28 md:mt-20">
          <div className="px-6 py-4">
            <h2 className="mb-2 text-3xl font-bold">Employee Engagement</h2>
            <p className="text-base text-gray-700">
              We provide good employee engagement as our organization trains and encourages
              employees to succeed in their roles and beyond. Our employees are recognized,
              appreciated, and awarded for their dedication and devotion to work. Moreover, we
              promote healthy work habits and team activities to create good bonding among employees
              which results in the organization&lsquo;s growth.
            </p>
            <br />
            <p>
              Employee engagement is influenced by a number of variables, and these variables might
              determine whether or not you keep your most valuable workers. Implementing actions in
              the following areas will help you increase engagement levels:
            </p>
            <br />
            <ul className="a">
              <li className="text-black-900">An efficient onboarding procedure</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Occupational culture</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">
                The availability of instruments that facilitate work
              </li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Providing opportunities for training and growth</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Good management</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Satisfactory internal communication</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Resources for workplace health and wellness</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Choices for flexible work</li>
            </ul>
            <br />
            <ul className="a">
              <li className="text-black-900">Rewards and acclaim</li>
            </ul>
            <br />
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default EmployeeEngagementReadMore;
