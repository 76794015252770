import React from "react";
import missionandvision from "../../../assets/missionandvision.png";
import ourMission from "../../../assets/our-mission-and-vission.svg";

const MissionAndVision = () => {
  return (
    <div className="flex flex-col md:flex-row items-center font-sans py-10">
      <div className="w-full">
        <img src={ourMission} alt="your-image-description" className="" />
      </div>
      <div className="w-3/4 md:w-10/12 lg:w-10/12">
        <h2 className="w-3/4 mb-4 content-end text-4xl font-semibold text-end">Our Mission</h2>
        <br />
        <p className="w-3/4 leading-7 text-end text-gray-500">
          We offer our customers excellent customer service, high-quality tools and supplies,
          qualified guidance and support, and information technologies that promote success. Provide
          our workers with incredible challenges, fulfillment, and the chance to greatly advance
          their careers.
        </p>
        <br />
        <h2 className="w-3/4 mb-4 text-4xl font-semibold text-end">Our Vision</h2>
        <br />
        <p className="w-3/4 leading-7 text-end text-gray-500">
          Our mission is to improve the lives of our consumers by offering high-quality goods at
          competitive prices while putting their needs first in all we do.
        </p>
      </div>
    </div>
  );
};

export default MissionAndVision;
