import React from "react";
import BlogBg from "../../assets/BlogBg.png";

const ClientReadMore = () => {
  return (
    <div id="client" className="flex items-center justify-center">
      <div>
        <div className="border  md:w-full">
          <img className="object-cover opacity-80 md:w-full" src={BlogBg} />
          <h3 className="text-6xl font-bold text-teal-300 relative bottom-[400px] text-center ">
            Client Engagement
          </h3>
        </div>
        <div className="md:mx-28 md:mt-20">
          <div className="px-6 py-4">
            <h2 className="mb-2 text-3xl font-bold">Client Engagement</h2>
            <p className="text-base text-gray-700">
              Every organization is known because of its clients and Pando India Software Consultant
              is the organization that provides the best services to clients. Clients from other
              regions have given positive feedback about us. Our organization’s tools, accuracy,
              speed, expertise, and specialization in different domains are the main reason for the
              strong relationship with market clients. We provide services within the deadline with
              a hundred percent accuracy, reliability, and scalability.
            </p>
            <br />
            <p className="text-base text-gray-700">
              Conversions and client engagement go hand in hand, yet establishing a devoted customer
              base takes time. There are numerous more hypotheses that determine how frequently a
              buyer must interact with your brand before they buy: Customer involvement is crucial
              for advancing prospects through your purchase cycle, even while the precise number of
              interactions required is controversial.
            </p>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default ClientReadMore;
