import React from "react";
import banner from "../../assets/fmcg-banner.png";
import fmcg from "../../assets/fmcg-ill.png";
import fmcgImage from "../../assets/fmcg-ill2.png";

export const Fmcg = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="FMCG" />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          FMCG
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                The fourth-largest sector of the Indian economy is the fast-moving consumer goods
                (FMCG) industry. Consumer packaged goods having a high turnover rate—i.e., those
                that are created, delivered, advertised, and consumed quickly—are what define it.
                The market is currently dominated by detergents, personal care products, dental care
                items, cosmetics, and other FMCG items. Pharmaceuticals, consumer electronics, soft
                drinks, packaged foods, and chocolates are also part of India&apos;s FMCG industry.
                Several companies dominate the market in various subsectors of the industry as a
                result of the large variety of items it produces.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">
                    Understanding Fast-Moving Consumer Goods
                  </p>
                  <p className="mt-2">
                    Consumption goods are items that the typical customer buys to use themselves.
                    Services, nondurable products, and durable goods are divided into three
                    categories. Things that are durable have a shelf life of three years or more,
                    whereas items that are not durable have one of less than a year. The majority of
                    consumer items fall under the category of fast-moving goods. They are considered
                    nondurables since they are quickly consumed and have a short shelf life.
                  </p>
                  <p className="mt-4">
                    Almost everyone on the earth uses fast-moving consumer goods (FMCG) every day.
                    These are the extras we pick up for ourselves at the produce stand, grocery
                    store, supermarket, and outlet warehouse. Examples include aspirin and other
                    over-the-counter medications, toilet paper, fruit and vegetables, milk, gum,
                    soda, and gum.
                  </p>
                  <p className="mt-4">
                    More than half of all consumer spending goes towards FMCGs, although these are
                    typically simple purchases. Consumers are more likely to display a durable
                    commodity like a new automobile or a well-made smartphone than a new energy
                    drink they paid $2.50 for at the convenience shop.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={fmcg} alt="fmcg-image" className="mt-5 mb-5" />
              </div>
            </div>
          </div>
          <div className="text-start">
            <p className="text-2xl font-bold text-black mt-4">
              Types of Fast-Moving Consumer Goods
            </p>
            <p className="mt-4">
              Fast-moving consumer goods, as was previously noted, are nondurable or goods having a
              short lifespan and are used quickly.
            </p>
            <p className="mt-4 font-semibold">
              FMCGs can be categorized into a number of distinct groups, including:
            </p>
            <ul className="pl-4">
              <li className="list-disc mt-4">
                Foods that have been processed include cheese, cereals, and boxed pasta.
              </li>
              <li className="list-disc mt-4">Prepared food prepared meals</li>
              <li className="list-disc mt-4">
                <b>Drinks: </b>Juices, energy drinks, and bottled water
              </li>
              <li className="list-disc mt-4">
                <b>Baking: </b>Bagels, croissants, and cookies
              </li>
              <li className="list-disc mt-4">
                Foods that are fresh, frozen, and dry: produce, frozen peas, carrots, raisins, and
                nuts are all examples of food.
              </li>
              <li className="list-disc mt-4">
                Aspirin, narcotic painkillers, and other medications are available over the counter.
              </li>
              <li className="list-disc mt-4">
                Cleaning supplies Oven cleaner, baking soda, and window and glass cleaner
              </li>
              <li className="list-disc mt-4">
                Toiletries and cosmetics toothpaste, soap, concealers, and hair care items
              </li>
              <li className="list-disc mt-4">
                <b>Writing instruments: </b>Markers, pencils, and pens
              </li>
            </ul>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={fmcgImage} alt="fmcg-image" className="mt-5" />
              </div>
              <div className="md:w-1/2 mt-10">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">The FMCG Industry</p>
                  <p className="">
                    Due to the quick turnover of fast-moving consumer goods, the market is not only
                    enormously large but also extremely competitive. Some of the biggest companies
                    in the world vie for market share in this industry, including Tyson Foods,
                    Coca-Cola, Unilever, Procter & Gamble, Nestlé, PepsiCo, and Danone. To intrigue
                    and attract customers to buy their items, companies like these must concentrate
                    their marketing efforts on quickly moving consumer goods.
                  </p>
                  <p className="mt-2">
                    As a result, packaging plays a crucial role in the manufacturing process. In
                    order to enhance efficiency, the logistics and distribution systems frequently
                    need secondary and tertiary packing. In addition to giving consumers information
                    and sales incentives, the unit pack or primary packaging is essential for the
                    preservation of the product and its shelf life.
                  </p>
                  <p className="mt-2">
                    FMCGs are regarded as a dependable source of income because they are sold in big
                    volumes. The low profit margins on each transaction are also offset by a high
                    volume of sales.
                  </p>
                  <p className="mt-2">
                    Although though FMCG stocks frequently have low growth prospects as investments,
                    they are safe bets with constant dividends, predictable margins, and reliable
                    returns.
                  </p>
                </div>
              </div>

              <div className="text-start mt-10">
                <p className="text-2xl font-bold text-black mt-4">
                  Some of the largest Fast-Moving Consumer Goods
                </p>
                <p className="mt-4 mb-10">
                  One of the largest fast-moving consumer products firms in the world is Coca-Cola,
                  followed by Nestlé and Procter & Gamble. As an illustration, the Swiss-based
                  Nestlé business is in charge of managing almost 2,000 trademarks, which include
                  anything from frozen foods to vitamins. Furthermore, the rapidly evolving consumer
                  products sector is characterised by severe competition for market share. As a
                  result, companies spend a lot of time and money on packaging, not just to attract
                  customers but also to preserve the product&apos;s purity and shelf life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
