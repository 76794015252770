import React from "react";
import getDemo1 from "../../assets/getDemo1.jpg";

export const GetDemo = () => {
  return (
    <div className="absolute sm:w-full sm:w-full z-5 h-auto top-[120px] left-0 flex justify-center px-3">
      {" "}
      <div className="sm:w-[1100px] relative sm:h-[650px] bg-white sm:rounded-[24px] sm:border-[1px] shadow-lg">
        <div className="justify-center px-4 font-sans sm:w-full sm:flex">
          <div className="pt-5 pb-5 sm:w-full">
            <img className="object-cover sm:w-[30rem] sm:h-[38rem] rounded-xl" src={getDemo1} />
          </div>
          <div className="relative sm:w-full z-2 sm:py-5 sm:w-[80%] sm:text-start">
            <button className="bg-[#53BCAC] sm:mx-3 sm:w-[400px] rounded-md font-semibold py-2 text-white text-2xl sm:mt-2 font-sans">
              Schedule a demo
            </button>
            <div className="justify-center my-6 sm:w-full item-center sm:mx-3">
              <p className="text-sm text-gray-500">
                We’d love the opportunity to share our industry-leading platform
                <br />
                with you.
              </p>
            </div>

            <div className="flex justify-center sm:w-[90%] md:my-8 item-center">
              <div>
                <input
                  placeholder="Name"
                  className="border-teal-400 border-2 appearance-none border-2 border-gray-200 sm:rounded-[10px] lg:w-[400px] py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                />
              </div>
            </div>
            <div className="flex justify-center my-8 sm:w-[90%] item-center">
              <div>
                <input
                  placeholder="Email Id"
                  className="border-teal-400 border-2 appearance-none border-2 border-gray-200 sm:rounded-[10px] lg:w-[400px] py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                />
              </div>
            </div>
            <div className="flex justify-center my-8 sm:w-[90%] item-center">
              <div>
                <input
                  placeholder="Mobile No."
                  className="border-teal-400 border-2 appearance-none border-2 border-gray-200 sm:rounded-[10px] lg:w-[400px] py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                />
              </div>
            </div>
            <div className="flex justify-center my-8 sm:w-[90%] item-center">
              <div>
                <input
                  placeholder="Country"
                  className="border-teal-400 border-2 appearance-none border-2 border-gray-200 sm:rounded-[10px] lg:w-[400px] py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                />
              </div>
            </div>
            <div className="flex justify-center my-8 sm:w-[90%] item-center">
              <div>
                <input
                  placeholder="Company"
                  className="border-teal-400 border-2 appearance-none border-2 border-gray-200 sm:rounded-[10px] lg:w-[400px] py-2 px-4 text-gray-100 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="inline-full-name"
                  type="text"
                />
              </div>
            </div>
            <div className="flex item-center md:mx-5">
              <div className="flex item-center w-[400px]">
                <div className="h-10 ">
                  <input type="checkbox" />
                </div>
                <div className="ml-2 text-sm">
                  <p className="mb-5 text-sm text-gray-500">
                    {" "}
                    Keep me updated of upcoming technology trends
                  </p>
                </div>
              </div>
            </div>
            <button className="sm:mx-3 bg-orange-600 w-[400px] rounded-md font-medium py-3 text-white">
              Shedule a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
