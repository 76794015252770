import React from "react";
import WorkWithUs from "../../assets/work-with-us.svg";
import line from "../../assets/line.svg";

export const About = () => {
  return (
    <div className="mt-10">
      <div className="w-full bg-[#99F6E4]">
        <div className="text-white">
          <div className="font-sans">
            <div className="flex flex-col-reverse items-center w-10/12 mx-auto md:flex-row lg:p-10">
              <div className="w-full md:w-1/2 lg:w-5/12">
                <img src={line} alt="" className="absolute md:mt-10 mt-8" />
                <p className="text-[#484848] font-bold text-5xl pt-2 lg:ml-5 md:ml-4 md:mt-10 pl-4 mt-8">
                  Why Work with Us?
                </p>
                <p className="w-full leading-7 text-justify text-[#484848] pt-8">
                  We as an organization provide associates with the chance to achieve revolutionary
                  results that benefit society as a whole and demonstrate the possibility of
                  anything via the application of innovation and our contextual expertise.
                </p>
                <br />
                <p className="w-full leading-7 text-justify text-[#484848]">
                  By giving them the chance to access and benefit from the immense collective
                  experience that exists within Pando India Software Consultant, we empower our
                  associates to create unique solutions. We make sure they continue to be at the
                  forefront of innovation.
                </p>
                <br />
                <p className="w-full leading-7 text-justify text-[#484848]">
                  Regardless of age or stage of their career, our people are encouraged in
                  discovering and becoming the professionals they were meant to be through
                  upskilling and reskilling as well as opportunities to move throughout the
                  business.
                </p>
              </div>
              <div className="flex items-center justify-center xl:justify-end ">
                <div className="relative w-full md:w-full flex justify-end">
                  <img src={WorkWithUs} alt="" className="lg:max-w-full md:w-10/12 lg:ml-28" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
