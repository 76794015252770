import BusinessmanPeople from "../../assets/Businesspeople.jpg";

export const Hero = () => {
  return (
    <div>
      <div className="flex items-center md:justify-start justify-center md:px-32 p-3 w-full mt-20 w-full py-10">
        <div className=" items-center md:w-[700px] w-full">
          <img src={BusinessmanPeople} alt="" className=" w-full rounded-xl" />
          <p className="text-center text-2xl my-10 text-gray-400 tracking-wider">
            Our offering our heroes
          </p>
        </div>
      </div>
    </div>
  );
};
