import React from "react";
import aboutdd from "../../../assets/aboutdd.png";
import whoWeAre from "../../../assets/Who-we-are.svg";
import star from "../../../assets/Star.svg";
import straight from "../../../assets/straight.svg";

export const WhoWeAre = () => {
  return (
    <div className="bg-orange-50">
      <div className="flex flex-col-reverse gap-6 mx-auto md:flex-row md:gap-0 font-sans md:flex-row items-center w-full">
        <div className="">
          <div className="">
            <img src={star} alt="" className="absolute left-0" />
          </div>
          <div className="pt-20 p-4 content-end">
            <div className="w-full">
              <p className="md:w-full leading-7 font-semibold text-gray-400 md:text-3xl sm:text-3xl lg:text-3xl">
                {" "}
                <img src={straight} alt="" className="absolute lg:ml-20 md:ml-12" />
                <span className="text-[#373737] text-4xl md:text-5xl lg:ml-24 pl-4 md:ml-12">
                  Who We Are?
                </span>{" "}
              </p>
              <br />
            </div>
          </div>
          <div className="p-4">
            <div className="left-0">
              <p className="md:w-4/5 lg:w-4/5 w-full leading-7 text-justify text-gray-500 lg:ml-20 md:ml-12">
                Pando India Software Consultant is one of the world’s leading software consulting
                companies. It was established on 28 December 2017 to provide clients with a better
                understanding of people and inspire growth.
              </p>
              <br />
              <p className="md:w-4/5 w-full leading-7 text-justify text-gray-500 lg:ml-20 md:ml-12">
                Pando&apos;s Service changes approach helps &apos;contract the center&apos; through
                the utilization of digital technologies across heritage conditions inside an
                enterprise, empowering organizations to remain ahead in an impacting world.
              </p>
              <br />
              <p className="md:w-4/5 w-full leading-7 text-justify text-gray-500 lg:ml-20 md:ml-12">
                We associate organizations and consumers and continue to convey bits of knowledge
                &apos;on request&apos; to organizations of all sizes and furnish customers with the
                capacity to shape the product they use.
              </p>
              <br />
              <br />
            </div>
          </div>
        </div>
        <div className="w-full">
          <img src={whoWeAre} alt="" className="" />
        </div>
      </div>
    </div>
  );
};
