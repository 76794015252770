import React from "react";
import workInCRM from "../../assets/work-crm.png";
import newsLetter from "../../assets/Subscribe-newsletter.svg";
import workContent from "../../assets/Work-content.svg";
import developmentCRM from "../../assets/Development-CRM.svg";
import { HashLink } from "react-router-hash-link";
import mediumShot from "../../assets/med-shot.png";
import digitalSurvey from "../../assets/digital-sur.png";
import groupPic from "../../assets/grp-pic.png";

const CRMReadMore = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="w-full flex items-center justify-center">
            <img className="h-auto w-full" src={workInCRM} />
          </div>
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <h1 className="text-3xl md:text-6xl font-bold text-center leading-relaxed">
                Work in CRM
              </h1>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-6 whitespace-pre-wrap">
                {" "}
                A customer relationship management (CRM) platform is a piece of software businesses
                use to control contacts with clients, keep records of their information, and
                automate various steps in the client&apos;s progression through the marketing and
                sales funnels. Every company ought to have a CRM system since it fosters customer
                loyalty and converts it into long-term profits.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-10/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-7/12">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit">
                  <p className="text-start text-2xl font-bold text-black pb-4">
                    The functionality offered by us using the CRM platform
                  </p>
                </div>
                <ul className="p-4">
                  <li className="list-disc text-start">
                    <b>Lead Management</b>
                    <p className="pt-2">
                      A CRM keeps track of the company&apos;s leads, enabling marketing teams to add
                      lnew leads (manually or automatically) and track and analyze information about
                      them.
                    </p>
                  </li>
                  <li className="list-disc text-start pt-4">
                    <b>Marketing Automation</b>
                    <p className="pt-2">
                      Some CRMs, such as HubSpot and Salesforce, provide marketing automation
                      features to streamline some time-consuming funnel chores. For instance, the
                      system can automatically publish social media posts on a schedule or send
                      clients marketing emails at times determined by the advertiser. Marketing
                      automation aims to maintain sales leads interest and convert them into paying
                      customers.
                    </p>
                  </li>
                  <li className="list-disc text-start pt-4">
                    <b>Sales Automation</b>
                    <p className="pt-2">
                      CRMs can keep track of customer interactions and automate some sales cycle
                      tasks that are essential for following up on leads and luring in new clients.
                    </p>
                  </li>
                  <li className="list-disc text-start pt-4">
                    <b>Workflow Automation</b>
                    <p className="pt-2">
                      CRM solutions assist firms in streamlining repetitive activities, freeing up
                      staff time for more creative and complex work.
                    </p>
                  </li>
                  <div className="flex justify-center">
                    <img src={workContent} alt="work-content" />
                  </div>
                  <li className="list-disc text-start pt-8">
                    <b>Analytics</b>
                    <p className="pt-2">
                      Built-in analytics capabilities in CRM platforms can provide insights and help
                      increase customer satisfaction rates. A marketer can examine the information
                      and develop tailored campaigns as a result. CRM analytics support attribution
                      tracking and offer perceptions into the standard of the customer experience.
                    </p>
                  </li>
                  <li className="list-disc text-start pt-4">
                    <b>Artificial Intelligence</b>
                    <p className="pt-2">
                      CRM tools like Salesforce have AI capabilities integrated into their systems
                      to automatically identify trends that lead to profitable sales, which can help
                      you create more precise marketing plans in the future.
                    </p>
                  </li>
                  <li className="list-disc text-start pt-4">
                    <b>Individualized Customer Experience</b>
                    <p className="pt-2">
                      Additionally, you may utilize a CRM to design tailored and consistent user
                      interfaces for your potential clients across different marketing platforms,
                      which could improve conversion rates and raise brand recognition.
                    </p>
                  </li>
                </ul>
                <h3 className="text-2xl mt-4 text-start font-bold p-4">
                  Development Process Of CRM
                </h3>
                <div className="flex justify-center">
                  <img src={developmentCRM} alt="" />
                </div>
                <p className="text-start text-black pb-4 mt-6">
                  There are additional costs associated with developing a well-integrated custom CRM
                  solution. It necessitates constant attention to and comprehension of the current
                  business procedures used by your corporation.
                </p>
                <p className="text-start text-black pb-4">
                  Since the client participates actively in the CRM development process, it is also
                  a perfect chance to step back and evaluate which processes in your business are
                  effective and which aren&apos;t. Although the actual development may demand more
                  time and focus from the staff, the finished product will precisely suit your
                  company&apos;s requirements, mirror its procedures, and map the client path
                  through the funnel.
                </p>
                <h3 className="text-2xl mt-4 text-start font-bold">Future Of CRM</h3>
                <p className="text-start text-black pb-4 mt-4">
                  Modern CRM systems are advancing and beginning to provide features beyond the
                  collection of customer data from various sources that they now accomplish. AI,
                  social network integration, comprehensive profiling, and many more things are now
                  commonplace.
                </p>
                <p className="text-start text-black pb-4">
                  You can anticipate that by working with a company that offers bespoke CRM
                  development, the end product will be scalable and ready to expand alongside your
                  business by closely mirroring its processes and connecting with existing systems.
                </p>
                <p className="text-start text-black pb-4">
                  This is an additional chance to capitalize on developing technology and establish
                  yourself as an industry pioneer with a competitive edge—long before the mainstream
                  suppliers catch up.
                </p>
              </div>
              <div className="sticky top-24 ml-auto bg-[#53BCAC] h-[550px] sm:w-[200px] md:w-[250px] lg:w-[350px] rounded-3xl p-6">
                <p className="text-white text-2xl text-justify">
                  <span className="text-[#90F5E6]">Subscribe</span> Newsletter
                </p>
                <p className="text-white pt-2 text-justify">
                  Get updated with our weekly blogs and expand your technical knowledge with our
                  in-depth content
                </p>
                <div className="relative mt-6">
                  <input
                    type="search"
                    className="block h-11 w-full p-4 text-sm outline-none text-gray-900 border border-gray-500 rounded-full bg-white dark:placeholder-gray-500 text-black"
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 h-11 bg-orange-500 focus:ring-4 focus:outline-none font-medium rounded-full text-md px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <div className="">
                  <img className="ml-auto pt-16 sticky flex justify-center" src={newsLetter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <h3 className="text-5xl text-[#53BCAC] font-bold">Related Blog</h3>
          <p className="text-lg text-gray-400 mt-2">
            Explore this space to stay tuned to our latest blog post.
          </p>
          <div>
            <div>
              <div
                id="blog-card"
                className="flex flex-wrap items-center w-full mt-5 justify-evenly md:mt-10 m-auto"
              >
                <div className="flex flex-row items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={mediumShot} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        Latest Technologies
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        In the era of advanced technology, it is necessary to remain updated with
                        the trending technology. Pando India Software Consultants is one of them
                        where the latest technologies are prominently used.
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/latest-technology"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={digitalSurvey} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
                        Digital Survey
                      </h2>
                      <p className="text-sm text-gray-600 p-3">
                        {" "}
                        The art and science of applying digital or electronic methods to determine
                        the terrestrial, that is the three-dimensional positions of points and the
                        distances and angles between them...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/digital"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-8"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center gap-10 justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={groupPic} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        5th Anniversary of Pando India Software Consultants
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        From the beginning, Pando India Software Consultant has been laying a solid
                        foundation. As PISC completes its five magnificent and wonderful years in
                        December 2022...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/anniversary"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-4"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRMReadMore;
