import React from "react";
import banner from "../../assets/market-banner.svg";
import market from "../../assets/market-ill.svg";
import market1 from "../../assets/market-ill1.svg";

const MarketResearchReadMore = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Market Research
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                By meticulously gathering information on people, firms, or both, market research
                aims to better understand the needs of a market. The outcomes of market research,
                which are typically condensed in a report, are then utilized to assist business
                owners in making decisions on the company&apos;s strategies, operations, and
                potential clientele.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-2 whitespace-pre-wrap">
                {" "}
                Where a corporation chooses to concentrate its efforts and resources can be
                influenced by understanding industry movements, shifting consumer requirements and
                preferences, and legislative trends, among other factors.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">Types of Market Research</p>
                  <p className="">
                    Although you can utilize a variety of market research instruments, there are
                    only really two sorts of market research data:
                  </p>
                  <p className="mt-2">
                    <b>Primary: </b>Primary data is information that you independently collect or
                    that you obtain with the aid of a market research company. It is in your hands.
                  </p>
                  <p className="mt-2">
                    <b>Secondary: </b>Secondary data is already-public information, such as
                    information found in government or business reports, publications, and
                    newspapers. The information is open to a wide audience, but you can also examine
                    the data in novel ways.
                  </p>
                  <p className="text-2xl font-bold text-black mt-8">
                    What are the Primary Market Research Tools?
                  </p>
                  <p className="mt-4">
                    Although primary research takes longer and costs more money, there are occasions
                    when it is the only method to obtain the information you require. The most
                    typical primary research instruments are:
                  </p>
                  <p className="mt-4">
                    <b>Surveys: </b>Customers can be surveyed to learn more about how they feel
                    about a product&apos;s features or about their hotel stay, for example, by
                    answering a series of questions on such topics. An individual can be given a
                    list of questions for a survey by phone, in person, on a card or piece of paper,
                    or online using survey software.
                  </p>
                  <p className="mt-4">
                    <b>Focus sessions: </b>A focus group is a gathering of individuals with similar
                    interests or lifestyles in order to better understand their likes and dislikes.
                    Focus groups typically have 8 to 12 participants, a facilitator, and discussion
                    questions. They are a valuable tool for gathering opinions on fresh goods,
                    features, or marketing initiatives.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2">
                <img src={market1} alt="work-content" className="lg:ml-24" />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <img src={market} alt="work-content" className="lg:pl-20" />
              </div>
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start md:ml-10">
                  <p className="text-2xl font-bold text-black">
                    What are the sources of Secondary Data?
                  </p>
                  <p className="mt-4">
                    Secondary research is frequently a smart place to start when performing market
                    research to better understand industry trends and more general movements. Among
                    the most helpful resources are:
                  </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-2">Trade publications for your sector</li>
                    <li className="list-disc mt-2">
                      Government reports, like the Census or the annual federal procurement results.
                    </li>
                    <li className="list-disc mt-2">
                      Industry analysts keep an eye on the performance of publicly traded businesses
                      in your industry.
                    </li>
                    <li className="list-disc mt-2">
                      See whether any university faculty members have published any research
                      reports.
                    </li>
                    <li className="list-disc mt-2">
                      <b>Websites - </b>Despite Wikipedia&apos;s unreliability, there may be others
                      that point you in the direction of dependable sources and reports.
                    </li>
                  </ul>
                  <p className="text-2xl font-bold text-black mt-8">
                    What is the main purpose of Market Research?
                  </p>
                  <p className="mt-4">
                    <b>Finding and comprehending the target market: </b>Market research aids
                    businesses in finding and comprehending their target markets. It can reveal
                    information on the tastes, wants, and motivations of the target market.
                  </p>
                  <p className="mt-4">
                    <b>Product/Service Development and Innovation: </b>Market research assists
                    businesses in determining the requirements of their target market and developing
                    new products and services to suit those demands. Additionally, it aids in their
                    understanding of potential market positioning for new goods and services.
                  </p>
                  <p className="mt-4">
                    <b>Market research aids businesses: </b>In planning and carrying out effective
                    market entry and expansion. They can use it to determine their target markets,
                    gauge the size of the market, and estimate the level of competition.
                  </p>
                  <p className="mt-4 mb-10">
                    <b>Brand and Reputation Management: </b>Market research assists businesses in
                    protecting and enhancing their reputation and brand. It can assist firms to
                    distinguish their brand from rivals and offer insights into client perception.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketResearchReadMore;
