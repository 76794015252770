import React from "react";
import banner from "../../assets/forex-banner.png";
import forex from "../../assets/forex-ill.png";
import forexImage from "../../assets/forex-ill2.png";

const ForexReadMore = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banking" />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Forex
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                Forex refers to the global electronic market for exchanging different currencies and
                currency derivatives (FX). Despite lacking a centralized physical presence, the FX
                market is the biggest and most liquid market in the world by trading volume, with
                trillions of dollars changing hands every day. Most trading is done by banks,
                brokers, and other financial institutions. Foreign and exchange are combined to form
                the term &quot;forex&quot;. It&apos;s often abbreviated to fx.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">How large the Forex is?</p>
                  <p className="mt-2">
                    The main characteristic that sets the forex market apart is its size.. The
                    volume of trading is typically very high. For instance, according to the Bank
                    for International Settlements, the average daily trading volume on foreign
                    exchange markets was $6.6 trillion in 2019. (BIS).
                  </p>
                  <p className="mt-2">
                    This is nearly 25 times greater than the volume of global equity (stock)
                    trading. The biggest foreign currency markets are found in major global
                    financial centers like London, New York, Singapore, Tokyo, Frankfurt, Hong Kong,
                    and Sydney.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={forex} alt="forex-image" className="mt-5 mb-5" />
              </div>
            </div>
          </div>
          <div className="text-start">
            <p className="text-2xl font-bold text-black mt-4">
              What is the procedure to trade in Forex?
            </p>
            <ul className="pl-4">
              <li className="list-disc mt-4">
                The currency market is open twenty-four hours a day, seven days a week, in the
                biggest financial centers in the globe. In essence, this means that you are always
                free to buy or sell currencies.
              </li>
              <li className="list-disc mt-4">
                In the past, the bulk of forex traders was employed by hedge funds, large
                corporations, and governments. FX trading is now open to anyone. Several banks,
                retail brokers, and financial firms allow anyone to open an account and trade
                currencies. You buy or sell a certain country&apos;s currency with respect to
                another currency when you trade on the forex market. Yet, there is no actual
                physical movement of money from one party to another, as with a currency exchange
                kiosk.
              </li>
              <li className="list-disc mt-4">
                In order to make a profit when trading on electronic markets, traders often place a
                stake in a certain currency in the hopes that there will be some upward movement and
                strength in the currency they are purchasing (or weakness if they are selling). A
                currency is compared to another currency each time it is traded. A currency is sold
                when it is purchased, and the opposite is true when a currency is sold. Your
                transaction pricing variance is what generates the profit.
              </li>
            </ul>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={forexImage} alt="forex-image" className="mt-5" />
              </div>
              <div className="md:w-1/2 mt-10">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">Forex Forward Transactions</p>
                  <p className="">
                    A forward is any foreign exchange transaction that settles for a date that is
                    later than the spot price. In order to account for the difference in interest
                    rates between the two currencies, the spot rate is adjusted to determine the
                    price. Just the difference in interest rates between the two markets is
                    reflected in the forward points. They do not represent a prediction of future
                    spot market activity.
                  </p>
                  <p className="mt-2">
                    An advance is a custom-made agreement. Any sum of money may be involved, and any
                    day other than a weekend or holiday may be used for settlement. On the
                    settlement day, monies are sent, just like in a spot transaction.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 pt-6">Forex Rollover</p>
                  <p className="mt-2">
                    Typically, retail traders don&apos;t want to accept delivery of the currency
                    they purchase. They just care about making money off of the price differential
                    between their transactions. As a result, most retail brokers &quot;roll
                    over&quot; their currency positions every day at 5 p.m. EST.
                  </p>
                  <p className="mt-2">
                    In essence, the broker resets the positions and offers a credit or debit for the
                    difference in interest rates between the two currencies in the held pairings.
                    Without the obligation to deliver or complete the deal, the trade continues.
                    Depending on the original transaction price and the price at which the trade was
                    closed, the trader realizes a profit or loss when the deal is finished.
                  </p>
                </div>
              </div>

              <div className="text-start mt-10">
                <p className="text-2xl font-bold text-black mt-4">Future of Forex</p>
                <p className="mt-4">
                  An agreement between two parties to deliver a specified quantity of money at a
                  specified future date, known as the expiry, is known as a forex or currency
                  futures contract. On an exchange, futures contracts are traded for predetermined
                  monetary values and have predetermined expiration dates.
                </p>
                <p className="mt-4">
                  The conditions of a futures contract cannot be altered, unlike a forward. Profit
                  is earned on the difference between the contract&apos;s purchase and sale prices.
                  As doing so would force them to deliver or settle the currency that the contract
                  represents, most speculators do not keep futures contracts until they expire.
                  Instead, before the contracts expire, speculators buy and sell them to realize
                  their gains or losses from the transactions.
                </p>

                <p className="text-2xl font-bold text-black mt-4">Example of Forex Transactions</p>
                <p className="mt-4">
                  Let&apos;s say a trader thinks the EUR will increase its value against the USD.
                  The USD will weaken in relation to the EUR, to put it another way.
                </p>
                <p className="mt-4 mb-10">
                  The trader invests $5,000 in currency and buys the EUR/USD at 1.2500. Later on
                  that day, the cost rose to 1.2550. (5000 * 0.0050) = $25 in profit for the trader.
                  The trader would lose $35 (5000 * 0.0070) should the price fall to 1.2430.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForexReadMore;
