import React from "react";
import { HashLink } from "react-router-hash-link";
import anniversaryBanner from "../../assets/img-grp.png";
import newsLetter from "../../assets/Subscribe-newsletter.svg";
import bussinessPeople from "../../assets/busi-people.png";
import mediumShot from "../../assets/med-shot.png";
import futureVision from "../../assets/future-vission.svg";
import digitalSurvey from "../../assets/digital-sur.png";

const AnniversaryReadMore = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="w-full flex items-center justify-start">
        <img className="h-auto w-full" src={anniversaryBanner} />
        <h1 className="absolute text-white xl:text-6xl lg:text-5xl md:text-4xl text-md pl-2 xl:pl-20 lg:pl-12 md:pl-12 font-extrabold">
          5th Anniversary
          <br /> Celebration
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-10/12 inline-block">
          <div className="flex text-justify pt-10 pb-12">
            <div className="w-full content-center">
              <p className="font-sans text-[#484848] left-0 leading-relaxed pt-5">
                {" "}
                <span className="text-4xl font-thin">F</span>rom the beginning, Pando India Software
                Consultant has been laying a solid foundation. As PISC completes its five
                magnificent and wonderful years in December 2022, we are overjoyed and delighted. We
                acknowledge the contributions of our customers, staff members, and directors to our
                ongoing success.
              </p>
              <p className="font-sans text-[#484848] left-0 leading-relaxed pt-5">
                {" "}
                Our fifth year of achievement is nothing less than a victory in and of itself. Our
                creative team has used proficiency and the appropriate strategy as a powerful
                instrument right here at the organization.
              </p>
              <p className="font-sans text-[#484848] left-0 leading-relaxed pt-5">
                {" "}
                Providing tools that aid companies in finding their next client is something that
                the professionals and creatives on the Pando India Software Consultant team are
                passionate about. The journey began about five years ago when one of the
                technologists and aficionados vowed to offer nothing less than the Finest!
              </p>
              <p className="font-sans text-[#484848] left-0 leading-relaxed pt-5">
                {" "}
                It&apos;s encouraging to note how much the PISC Service and team have developed when
                considering the last five years of the process. As we have assisted our clients in
                boosting their businesses with innovative ideas, we have successfully established a
                genuinely global firm that is proud of touching lives with inspired software. We are
                Industry pioneers in digital marketing and have aided numerous brands in achieving
                their corporate objectives.
              </p>
              <p className="font-sans text-[#484848] left-0 leading-relaxed pt-5">
                {" "}
                One of the biggest businesses in the fields of AI Tools, Cloud-based DevOps
                operations, web design and development, digital marketing, and many others, Pando
                India Software Consultant has a proud history of making some great accomplishments
                year after year. Since launching in 2017, PISC has significantly grown its staff,
                and this has all contributed to a very unique 2022.
              </p>
              <p className="font-sans text-[#484848] left-0 leading-relaxed pt-5">
                {" "}
                Here&apos;s to a successful beginning and many more years of creating beauty! Happy
                five year Anniversary.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-10/12 inline-block">
          <div className="flex justify-center mt-4">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-7/12">
                <h2 className="text-3xl text-start font-bold">Future vision</h2>
                <div className="flex justify-center my-4">
                  <img src={futureVision} alt="" />
                </div>
                <p className="text-start text-black pb-4">
                  Although an AI language model cannot foresee the future with certainty, it can be
                  given specific scenarios for an Indian software development company that partners
                  with an American AI service provider.
                </p>
                <p className="text-start text-black pb-4 mt-2">
                  <b>Expansion into new markets:</b> The company might broaden its reach into other
                  markets by utilizing its skills in AI and software development. They can look for
                  new customers in Europe or Asia and form alliances with other businesses there or
                  abroad.
                </p>
                <p className="text-start text-black pb-4 mt-2">
                  <b>Diversification of services:</b> The organization might broaden the scope of
                  its offerings beyond AI and software development and start providing solutions for
                  things like cybersecurity, cloud computing, or digital transformation. This would
                  allow the company to provide their current customers with more value and draw in
                  new ones.
                </p>
                <p className="text-start text-black pb-4 mt-2">
                  <b>Investments in research and development:</b> The company may invest in research
                  and development to stay ahead of the competition and to develop new AI
                  technologies that can be used in various industries. This would require a
                  significant investment in talent, resources, and infrastructure, but it could lead
                  to substantial growth and profitability.
                </p>
                <p className="text-start text-black pb-4 mt-2">
                  <b>Focus on Sustainability:</b> The company might embrace a sustainability-focused
                  strategy, creating AI solutions that are socially and environmentally responsible.
                  This would necessitate a strong commitment to sustainability, and it might aid the
                  company in standing out from rivals and drawing in socially concerned customers.
                </p>
                <p className="text-start text-black pb-4 mt-2">
                  <b>Acquisition or partnership with other companies:</b> The company might think
                  about partnering with or purchasing other companies based in the same or
                  comparable industry. This might aid the company in growing its customer base,
                  expanding its product line, and entering new markets. It might also give access to
                  fresh talent or technological advancements.
                </p>
                <p className="text-start text-black pb-4 mt-2">
                  Ultimately, the ability of the software company to innovate, adapt to shifting
                  market conditions, and offer value to their clients will determine how successful
                  they are in the future. The company can position itself for success by being at
                  the forefront of technology and business processes.
                </p>
                <h2 className="text-3xl mt-4 text-start font-bold">Road Map</h2>
                <ul className="ml-5">
                  <li className="list-disc text-start mt-5">
                    We&apos;ll establish a staff for marketing and sales that sources lead from the
                    global software development market.
                  </li>
                  <li className="list-disc text-start mt-2">
                    Finding the top talent nationwide and developing their programming skills in
                    cutting-edge technologies like advanced JavaScript, python, and AI will help us
                    build a team that will provide services to our international partners.
                  </li>
                  <li className="list-disc text-start mt-2">
                    Develop IT-based tools to provide care for large populations.
                  </li>
                  <li className="list-disc text-start mt-2">
                    We anticipate that deals will be converted in a few months because certain
                    conversations are at an advanced stage.
                  </li>
                </ul>
              </div>
              <div className="sticky top-24 ml-auto bg-[#53BCAC] h-[550px] sm:w-[200px] md:w-[250px] lg:w-[350px] rounded-3xl p-6">
                <p className="text-white text-2xl text-justify">
                  <span className="text-[#90F5E6]">Subscribe</span> Newsletter
                </p>
                <p className="text-white pt-2 text-justify">
                  Get updated with our weekly blogs and expand your technical knowledge with our
                  in-depth content
                </p>
                <div className="relative mt-6">
                  <input
                    type="search"
                    className="block h-11 w-full p-4 text-sm outline-none text-gray-900 border border-gray-500 rounded-full bg-white dark:placeholder-gray-500 text-black"
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 h-11 bg-orange-500 focus:ring-4 focus:outline-none font-medium rounded-full text-md px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <div className="">
                  <img className="ml-auto pt-16 sticky flex justify-center" src={newsLetter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <h3 className="text-5xl text-[#53BCAC] font-bold">Related Blog</h3>
          <p className="text-gray-400 mt-2">
            Explore this space to stay tuned to our latest blog post.
          </p>
          <div>
            <div>
              <div
                id="blog-card"
                className="flex flex-wrap items-center w-full mt-5 justify-evenly md:mt-10 m-auto"
              >
                <div className="flex flex-row items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={bussinessPeople} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        Work in CRM
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        A customer relationship management (CRM) platform is a piece of software
                        businesses use to control contacts with clients, keep records of their
                        information, and automate various steps in the client&apos;s...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/crm/#crm"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={mediumShot} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
                        Latest Technology
                      </h2>
                      <p className="text-sm text-gray-600 p-3">
                        {" "}
                        In the era of advanced technology, it is necessary to remain updated with
                        the trending technology. Pando India Software Consultants is one of them
                        where the latest technologies are prominently used.
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/latest-technology"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-8"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center gap-10 justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={digitalSurvey} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        Digital Survey
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        The art and science of applying digital or electronic methods to determine
                        the terrestrial, that is the three-dimensional positions of points and the
                        distances and angles between them...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/digital"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnniversaryReadMore;
