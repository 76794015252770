import applyIMg3 from "../../assets/applyImg3.png";

export const Startup = () => {
  return (
    <div>
      <div className="flex flex-wrap items-center justify-center gap-10 w-full p-5 ">
        <div className="items-center mx-5 w-[700px] h-auto ">
          <div>
            <h1 className="text-4xl text-gray-500 tracking-wider font-bold ">
              Startups that are shaping our lives every day
            </h1>
            <p className="startup_Pera">
              We understand your business requirements and transform them with the right mobile app
              solutions by putting your customer experience in the center of design – And we do that
              with Scalability, Speed & Security.
            </p>
            <p className="startup_Pera">
              We understand your business requirements and transform them with the right mobile app
              solutions by putting your customer experience in the center of design – And we do that
              with Scalability, Speed & Security.
            </p>
            <p className="startup_Pera">
              We understand your business requirements and transform them with the right mobile app
              solutions by putting your customer experience in the center of design – And we do that
              with Scalability, Speed & Security.
            </p>
            <p className="startup_Pera">
              We understand your business requirements and transform them with the right mobile app
              solutions by putting your customer experience in the center of design – And we do that
              with Scalability, Speed & Security.
            </p>
            <p className="startup_Pera">
              We understand your business requirements and transform them with the right mobile app
              solutions by putting your customer experience in the center of design – And we do that
              with Scalability, Speed & Security.
            </p>
          </div>
        </div>

        <div className="flex justify-center w-[500px] h-[600px]">
          <div className=" bg-teal-500 p-5 w-[300px] ">
            {" "}
            <p className="text-start text-gray-600 mb-5">
              {" "}
              <span className="text-teal-300 text-2xl px-2">Subscribe</span>{" "}
              <span className="text-white text-2xl">Newsletter</span>
            </p>
            <p className="text-md text-bold text-start text-white mb-20">
              {" "}
              Get updated with our weekly blogs and expand your technical knowledge with our
              in-depth content
            </p>
            <div className="relative ">
              <input type="text" className=" p-1 w-full rounded-2xl h-9" />
              <div className="absolute  top-0.5 right-0.5">
                <button className="relatedBtn">Submit</button>
              </div>
            </div>
            <div className="py-10">
              <img className="object-cover h-30 rounded-xl" src={applyIMg3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
