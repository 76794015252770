import React from "react";
import banner from "../../assets/online-banner.svg";
import online from "../../assets/online-ill.svg";
import online1 from "../../assets/online-ill2.svg";

const ReadMorePage = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Online Trading
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                Online trading has greatly simplified and expedited the process of purchasing and
                selling shares. Trading can now be done instantly and remotely from anywhere thanks
                to the internet, which has taken over the world stage. It is essential to the life
                of a trader or investor. An online trading platform, it makes it easier to buy and
                sell financial items like stocks, bonds, NCDs, equities, and ETFs.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-2 whitespace-pre-wrap">
                {" "}
                Before digitalization, a shareholder would call their broker and ask to acquire
                stocks at a certain price if they wanted to purchase shares. The broker will then
                confirm the order after speaking with their client about the share&apos;s current
                market price. The order would be registered on the stock exchange following this
                tiresome process.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="grow lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">How to trade Online?</p>
                  <ul className="pl-4">
                    <li className="list-disc mt-2">
                      <p>
                        Applying for a Demat and trading account with a Depository Participant and
                        completing all required documentation processes is the first step in
                        beginning to trade online. The Securities and Exchange Board of India (SEBI)
                        certification and registration with all stock exchanges are both crucial
                        requirements when selecting a broker.
                      </p>
                      <p className="mt-2">
                        You must have sufficient knowledge of how the stock market operates before
                        you begin online trading. The fundamentals of online trading are covered in
                        a variety of courses. In addition, to stay informed about the financial
                        markets, you should monitor financial news, websites, and podcasts.
                      </p>
                    </li>
                    <li className="list-disc mt-2">
                      <p>
                        Additionally crucial is developing a strategy and a plan. Before you start
                        investing real money, it is advised that you start practicing with a virtual
                        trading account. This will provide you the chance to familiarize yourself
                        with price dynamics and develop the trading strategy you desire.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={online} alt="work-content" className="mt-5" />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={online1} alt="work-content" className="mt-5 mb-10" />
              </div>
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start md:ml-10 mt-10">
                  <p className="text-2xl font-bold text-black">
                    What are the benefits of Online Trading?
                  </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">
                      <p>
                        <b>Easier and Convenient: </b>
                        Life is made easier and more convenient by anything that can be done online.
                        Online trading enables traders to do deals quickly and easily. If you have
                        an online Demat account and a working internet connection, you can save time
                        and effort.
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b>Leaner Costs: </b>
                        You must pay a charge or commission to a broker when they carry out your
                        trades, which increases the cost to you. However, you pay a cost, or a
                        brokerage charge, when you trade online that is significantly less than the
                        fee levied by the broker.
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b> Total Control: </b>
                        Online trading enables you to have total control over your own portfolio,
                        providing you with more power over your assets. You can now make judgments
                        and trade whenever the market is open. Now, you can trade whenever the
                        market is open and make decisions on your own without the broker&apos;s
                        involvement.
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b>Track investments at any time: </b>
                        You can follow investments at any time using websites and mobile
                        applications. By observing current gains and losses, you may swiftly select
                        which stocks you want to keep or sell.
                      </p>
                    </li>
                  </ul>
                  <p className="text-2xl font-bold text-black mt-4">
                    What to remember before starting Online Trading?
                  </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">
                      <p>Having a Demat and trading account is required.</p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>Choose a broker who can suit your needs.</p>
                    </li>
                    <li className="list-disc mt-4 mb-10">
                      <p>Gather sufficient information and conduct analysis before making deals.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadMorePage;
