import applyIMg1 from "../../assets/applyImg1.png";
import applyIMg2 from "../../assets/applyImg2.png";
import { Startup } from "./Startup";
import { Hero } from "./Hero";
import { RelatedBlog } from "./RelatedBlog";

export const Applyhome = () => {
  return (
    <div>
      <div
        id="apply-now-blog"
        className="flex flex-wrap items-center justify-center w-full gap-20 p-3 md:p-3 md:mt-2"
      >
        <div className="items-center w-[700px]">
          <img src={applyIMg1} alt="" className="md:scale-[100%]   rounded-xl " />
          <div className="p-2 flex justify-between text-[20px]">
            <h2 className="text-gray-500 ">7 November ,2022</h2>
            <div className="flex">
              <img className="object-cover px-2 h-[35px] rounded-[50%] w-[50px]" src={applyIMg2} />
              <span>
                <h2 className="text-gray-500">Nikit Singh</h2>
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-10 w-[500px] h-[300px]">
          <div className="">
            {" "}
            <p className="mb-10 text-2xl text-center text-gray-500 text-black-500 text-bold">
              Latest Blog
            </p>
            <img className="object-cover w-full h-40 rounded-xl" src={applyIMg2} />
            <div className="p-2 flex justify-between text-[15px]">
              <h2 className="text-gray-500 ">25 Nov 2022</h2>
              <div className="flex ">
                <img
                  className="object-cover px-2 h-[22px] rounded-[50%] w-[35px]"
                  src={applyIMg2}
                />
                <span>
                  <h2 className="text-gray-500">Nikit SIngh </h2>
                </span>
              </div>
            </div>
            <h2 className="text-gray-500 ">
              Startups that are shaping ourlives <br /> every day
            </h2>
          </div>
        </div>
      </div>
      <Startup />
      <Hero />
      <RelatedBlog />
    </div>
  );
};
