import React from "react";
import banner from "../../assets/Cloud-banner.svg";
import cloud from "../../assets/cloud-ill.svg";
import cloud1 from "../../assets/cloud-ill1.svg";

const CloudServices = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Cloud Services
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                The term &quot;cloud services&quot; refers to a wide range of services that are made
                readily available to customers and organizations online. These programmes and
                services were created to make it simple for users to access information and software
                without the need for their own hardware or infrastructure. Most employees utilise
                cloud services during the course of the workday for everything from checking email
                to collaborating on papers, whether they are aware of it or not.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-2 whitespace-pre-wrap">
                {" "}
                The management of cloud services is solely the responsibility of cloud computing
                vendors and service providers. A business is not obliged to host apps on its own
                internal servers since clients can access them via the providers servers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="grow lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">
                    What are the types of Cloud Services?
                  </p>
                  <p className="mt-2">There are mainly three types of Cloud Services : </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">
                      <p>
                        <b>Software as a Service (SaaS): </b>
                        Software as a service, or SaaS, is the name given to the category of cloud
                        services that is the most well-known. This broad category includes a number
                        of services, including tools for project management, web-based email, and
                        file storage and backup.
                      </p>
                      <p></p>
                      <p className="mt-2">
                        Dropbox, G Suite, Microsoft Office 365, and Slack are a few examples of SaaS
                        cloud service providers. Users of each of these programs can access, share,
                        store, and safeguard data on &quot;the cloud.&quot;
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b>Infrastructure as a Service (IaaS): </b>
                        The infrastructure that many cloud service providers require to manage SaaS
                        tools—but do not wish to maintain themselves—is provided by infrastructure
                        as a service or IaaS. By acting as the full data center foundation, it does
                        away with the requirement for time- and resource-consuming on-site
                        deployments.
                      </p>
                      <p className="mt-2">
                        Amazon Web Services (AWS), Microsoft Azure, and Google Compute Engine are a
                        few examples of IaaS. In addition to maintaining all storage servers and
                        networking hardware, these service providers might also offer load
                        balancing, application firewalls, and other services. Several renowned SaaS
                        companies employ IaaS platforms.
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b>Platform as a Services: </b>
                        Platform as a Service (PaaS) is a cloud service concept that allows
                        developers to create cloud apps in a web-based environment. PaaS provides a
                        database, operating system, and programming language that companies may use
                        to develop cloud-based software without having to handle the underlying
                        components.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={cloud} alt="work-content" className="mt-5" />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={cloud1} alt="work-content" className="mt-5 mb-10" />
              </div>
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start md:ml-10 mt-10">
                  <p className="text-2xl font-bold text-black">
                    What are the benefits of Cloud Services?
                  </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">
                      <p>
                        <b>Cloud services solutions improve scalability</b>
                      </p>
                      <p className="mt-2">
                        A company does not need to invest in its own resources or hire more IT staff
                        because the cloud service provider provides the infrastructure and software
                        required to run the service. The organization will therefore find it easy to
                        scale the solution as user demands change, whether via the addition of
                        additional licenses to accommodate a growing workforce or through the
                        expansion and improvement of the apps themselves.
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b>Decreased costs</b>
                      </p>
                      <p className="mt-2">
                        Due to the availability of monthly or yearly subscription options from many
                        cloud providers, on-premises software licensing purchases are no longer
                        necessary. As a result, businesses don&apos;t have to pay for the underlying
                        infrastructure or deal with upkeep and upgrades in order to use software,
                        storage, and other services.
                      </p>
                    </li>
                    <li className="list-disc mt-4">
                      <p>
                        <b>Increased adaptability with cloud services</b>
                      </p>
                      <p className="mt-2 mb-10">
                        Businesses can purchase services as needed and on-demand by utilizing the
                        cloud. The business can quickly terminate the subscription or stop providing
                        the service if a certain platform or application is no longer required.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudServices;
