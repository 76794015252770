import React from "react";
import banner from "../../assets/enterprise-banner.png";
import enterprise from "../../assets/enterprise.svg";

const Enterprise = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Enterprise Development
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="font-sans text-[#484848] text-start leading-relaxed whitespace-pre-wrap">
                    Enterprise software services refer to cloud-based or web-based applications that
                    are designed to meet the needs of large organizations or enterprises. These
                    services typically provide functionalities and capabilities that are tailored
                    for managing and optimizing business processes, operations, and data at an
                    enterprise scale. Enterprise software services are used by businesses to
                    streamline their operations, enhance productivity, improve decision-making, and
                    achieve business objectives.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>Enterprise Resource Planning (ERP) Systems: </b>ERP systems are comprehensive
                    software solutions that integrate and automate various business processes, such
                    as finance, human resources, supply chain management, procurement,
                    manufacturing, and customer relationship management. ERP systems provide a
                    unified and centralized view of an organization&apos;s operations and enable
                    efficient management of resources, processes, and data across the entire
                    enterprise.
                  </p>
                  <p className="mt-4">
                    <b>Customer Relationship Management (CRM) Systems: </b>CRM systems are designed
                    to manage an organization&apos;s interactions with customers, including sales,
                    marketing, and customer service. CRM systems provide features for tracking
                    customer interactions, managing leads, managing sales pipelines, analyzing
                    customer data, and improving customer engagement and retention.
                  </p>
                  <p className="mt-4">
                    <b>Human Resources Management Systems (HRMS): </b>HRMS systems are designed to
                    streamline and automate human resources processes, including recruitment,
                    employee onboarding, employee data management, benefits administration,
                    performance management, and employee self-service. HRMS systems provide a
                    centralized and integrated platform for managing human resources operations and
                    data.
                  </p>
                  <p className="mt-4">
                    <b>Business Intelligence and Analytics Platforms: </b>
                    Business intelligence and analytics platforms provide tools and capabilities for
                    analyzing and visualizing large amounts of data to gain insights and make
                    data-driven decisions. These platforms may include features such as data
                    visualization, data exploration, data modeling, and advanced analytics to enable
                    organizations to derive meaningful insights from their data and drive business
                    growth.
                  </p>
                  <p className="mt-4">
                    <b>Supply Chain Management (SCM) Systems: </b>
                    SCM systems are designed to manage the end-to-end supply chain processes,
                    including procurement, inventory management, logistics, transportation, and
                    demand planning. SCM systems provide visibility, control, and optimization of
                    supply chain operations to improve efficiency, reduce costs, and enhance
                    customer satisfaction.
                  </p>
                  <p className="mt-4">
                    <b>Document Management Systems: </b>
                    Document management systems are designed to facilitate the creation, storage,
                    retrieval, and management of documents and files within an organization. These
                    systems provide features for document collaboration, version control, document
                    workflow, and document security to improve document management and information
                    sharing across the organization.
                  </p>
                  <p className="mt-4">
                    <b>Project Management Tools: </b>
                    Project management tools provide features for planning, organizing, and managing
                    projects within an organization. These tools may include features such as
                    project scheduling, task management, team collaboration, and project reporting
                    to streamline project execution and improve team productivity.
                  </p>
                  <p className="mt-6 mb-10">
                    Enterprise software services are typically scalable, customizable, and secure to
                    meet the unique needs and requirements of large organizations. They are used by
                    businesses of all sizes and industries to streamline their operations, enhance
                    productivity, and achieve business goals.
                  </p>
                </div>
              </div>
              <img src={enterprise} alt="enterprise-img" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enterprise;
