import React from "react";
import { HashLink } from "react-router-hash-link";
import dropCV from "../../assets/drop-cv.svg";
import ApplyForm from "../ApplyForm";

const FullStackApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Full Stack Developer
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold">Job brief</h2>
          <p className="text-start mt-2">
            We are looking for a Full Stack Developer using{" "}
            <b>JavaScript (Minimum Exp 2 to 5 yr)</b>, to produce scalable software solutions.
            You&apos;ll be part of a cross-functional team that’s responsible for the full software
            development life cycle, from conception to deployment
          </p>
          <p className="text-start mt-4">
            As a Full Stack Developer, you should be comfortable with both front-end and back-end
            coding languages, development frameworks, and third-party libraries. You should also be
            a team player with a knack for visual design and utility. To meet the changing world of
            technological challenges, we follow Agile Methodology.
          </p>
          <h2 className="text-start text-2xl font-bold mt-6">Responsibilities</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Work with development teams and product managers to ideate software solutions
            </li>
            <li className="mt-2">Design client-side and server-side architecture</li>
            <li className="mt-2">
              Build the front-end of applications through appealing visual design using HTML5, CSS3,
              and React.
            </li>
            <li className="mt-2">Develop and manage well-functioning databases and applications</li>
            <li className="mt-2">Write effective APIs</li>
            <li className="mt-2">Test software to ensure responsiveness and efficiency</li>
            <li className="mt-2">Troubleshoot, debug and upgrade software</li>
            <li className="mt-2">Create security and data protection settings</li>
            <li className="mt-2">
              Build features and applications with a mobile responsive design
            </li>
            <li className="mt-2">Write technical documentation</li>
            <li className="mt-2">Work with data scientists and analysts to improve software</li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Requirements and skills</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Proven experience as a Full Stack Software Developer using JavaScript.
            </li>
            <li className="mt-4">Familiarity with common stacks</li>
            <li className="mt-4">
              Knowledge of multiple front-end languages and libraries (e.g. HTML/ CSS, JavaScript,
              ReactJs)
            </li>
            <li className="mt-4">Knowledge of Nodejs</li>
            <li className="mt-4">Excellent knowledge of MySQL databases.</li>
            <li className="mt-4">Excellent communication and teamwork skills</li>
            <li className="mt-4">Excellent Team Player.</li>
            <li className="mt-4">Great attention to detail</li>
            <li className="mt-4">Organizational skills</li>
            <li className="mt-4">An analytical mind</li>
          </ul>
          <div id="application-form">
            <ApplyForm type={"FullStack"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullStackApplyNow;
