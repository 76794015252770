import React from "react";
import { HashLink } from "react-router-hash-link";
import advanceBanner from "../../assets/Advanced-Tech.png";
import latestTechDbs from "../../assets/Latest-tech-dbs.svg";
import newsLetter from "../../assets/Subscribe-newsletter.svg";
import latestTech from "../../assets/Latest-tech.svg";
import bussinessPeople from "../../assets/busi-people.png";
import digitalSurvey from "../../assets/digital-sur.png";
import groupPic from "../../assets/grp-pic.png";

const LatestTechnology = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="w-full flex items-center justify-center">
            <img className="h-auto w-full" src={advanceBanner} />
          </div>
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <h1 className="text-3xl md:text-6xl font-bold text-center leading-relaxed">
                Latest Technology
              </h1>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-6 whitespace-pre-wrap">
                {" "}
                Today&apos;s technology is developing quickly, enabling quicker change and
                advancement and accelerating the rate of change. Yet, there are many more changes
                that have occurred this year as a result of the COVID-19 epidemic, which has made IT
                professionals recognize that their job will alter in the contactless world of the
                future. And an IT professional will be continually learning, unlearning, and
                relearning in 2023–2024. Some of the latest technologies trends are given below.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-10/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-7/12">
                <h2 className="text-2xl mt-4 text-start font-bold">Computing Power</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Since practically every appliance and gadget in the digital age is already
                  computerized, computing power has already cemented its position in the world. And
                  it&apos;s here to stay since data science specialists forecast that the computing
                  infrastructure we are currently constructing will only improve over time.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Smarter Devices</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Our world is now more intelligent and well-functioning thanks in large part to
                  artificial intelligence. It goes above and beyond mere human simulation to make
                  our lives easier and more convenient. As data scientists develop AI household
                  robots, appliances, work devices, wearables, and much more, these smarter products
                  will be around well into the future, possibly even beyond 2023.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Artificial Intelligence</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Even though artificial intelligence, or AI, has generated a lot of noise over the
                  past ten years, it is still one of the newest technological revolutions because
                  its implications on how we live, work, and play are just beginning to be seen. AI
                  is already well-known for its excellence in a wide range of fields, including
                  ride-sharing apps, smartphone personal assistants, image and speech recognition,
                  navigation apps, and much more.
                </p>

                <div className="flex justify-center my-2">
                  <img src={latestTech} alt="" />
                </div>

                <h2 className="text-2xl mt-4 text-start font-bold">Machine Learning</h2>
                <p className="text-start text-black pb-4 mt-2">
                  A tremendous demand for trained workers is being generated by the deployment of
                  machine learning, a subset of artificial intelligence. By 2025, AI, machine
                  learning, and automation are expected to provide 9% of all new jobs in the United
                  States. These jobs will include robot monitors, data scientists, automation
                  specialists, and content curators.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Digital Trust</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Digital technologies have gained people&apos;s faith and trust as a result of how
                  well they accommodate and entangle individuals with devices and technologies. This
                  well-known digital trust is a crucial trend that will lead to additional
                  inventions. Digital conviction is the conviction that technology can create a
                  secure, safe, and dependable digital world, allowing businesses to innovate
                  without having to worry about maintaining the public&apos;s trust.
                </p>

                <h2 className="text-2xl mt-4 text-start font-bold">Robotic Process Automation</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Robotic Process Automation, or RPA, is a technology that is automating
                  occupations, much like AI and Machine Learning. RPA refers to the use of software
                  to automate business operations, including application interpretation, transaction
                  processing, data handling, and even email answering. RPA automates routine
                  processes that previously required human labor.
                </p>
                <div className="flex justify-center my-2">
                  <img src={latestTechDbs} alt="" />
                </div>

                <h2 className="text-2xl mt-4 text-start font-bold">Blockchain Technology</h2>
                <p className="text-start text-black pb-4 mt-2">
                  Although the majority of people only associate blockchain technology with digital
                  currencies like Bitcoin, it actually provides security that is beneficial in a
                  variety of other contexts. Blockchain may be summed up as data that you can only
                  add to, not subtract from, or change. Because you are creating a chain of data,
                  the term &quot;chain&quot; is appropriate.
                </p>
              </div>
              <div className="sticky top-24 ml-auto bg-[#53BCAC] h-[530px] sm:w-[200px] md:w-[250px] lg:w-[350px] rounded-3xl p-6">
                <p className="text-white text-2xl text-justify">
                  <span className="text-[#90F5E6]">Subscribe</span> Newsletter
                </p>
                <p className="text-white pt-2 text-justify">
                  Get updated with our weekly blogs and expand your technical knowledge with our
                  in-depth content
                </p>
                <div className="relative mt-6">
                  <input
                    type="search"
                    className="block h-11 w-full p-4 text-sm outline-none text-gray-900 border border-gray-500 rounded-full bg-white dark:placeholder-gray-500 text-black"
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 h-11 bg-orange-500 focus:ring-4 focus:outline-none font-medium rounded-full text-md px-4 py-2"
                  >
                    Submit
                  </button>
                </div>
                <div className="">
                  <img className="ml-auto pt-16 sticky flex justify-center" src={newsLetter} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <h3 className="text-5xl text-[#53BCAC] font-bold">Related Blog</h3>
          <p className="text-gray-400 mt-2">
            Explore this space to stay tuned to our latest blog post.
          </p>
          <div>
            <div>
              <div
                id="blog-card"
                className="flex flex-wrap items-center w-full mt-5 justify-evenly md:mt-10 m-auto"
              >
                <div className="flex flex-row items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={bussinessPeople} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        Work in CRM
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        A customer relationship management (CRM) platform is a piece of software
                        businesses use to control contacts with clients, keep records of their
                        information, and automate various steps in the client&apos;s...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/crm/#crm"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-10"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={digitalSurvey} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">
                        Digital Survey
                      </h2>
                      <p className="text-sm text-gray-600 p-3">
                        {" "}
                        The art and science of applying digital or electronic methods to determine
                        the terrestrial, that is the three-dimensional positions of points and the
                        distances and angles between them...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/digital/#digital"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-8"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
                <div className="items-center gap-10 justify-evenly mt-5 md:mt-10">
                  <div className="transition-all m-5 duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
                    <img src={groupPic} alt="" className="rounded-3xl w-full p-2 h-56" />
                    <div className="text-center">
                      <h2 className="flex justify-center text-lg font-bold text-gray-600">
                        5th Anniversary of Pando India Software Consultants
                      </h2>
                      <p className=" text-sm text-gray-600 p-3">
                        {" "}
                        From the beginning, Pando India Software Consultant has been laying a solid
                        foundation. As PISC completes its five magnificent and wonderful years in
                        December 2022...
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <HashLink
                        role="button"
                        smooth
                        to="/anniversary"
                        onClick={handleClick}
                        className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 m-4"
                      >
                        Read More
                      </HashLink>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestTechnology;
