import { useState } from "react";
import career_group from "../../assets/career_group.png";
import { Model } from "../Model/Model";
import ourCulture from "../../assets/holi-pic.png";

export const Joinsection = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      <div className="flex flex-col text-white w-full bg-[#FFEDE2] my-12">
        <div className="flex flex-col-reverse font-sans mx-auto items-center w-full gap-6 mx-auto md:flex-row md:gap-0">
          <div className="w-full md:w-full">
            <div className="lg:w-3/4 md:w-full w-full p-3 mx-auto">
              <p className="lg:w-3/4 md:w-full p-4 w-full leading-[2rem] md:mt-4 text-[2rem] md:text-[3rem] text-[#484848] font-semibold md:text-left">
                Our Culture
              </p>
              <br />
              <p className="leading-[1.5rem] p-4 md:leading-[1.5rem] text-[1rem] md:text-sm text-justify text-[#484848] lg:w-full md:w-full md:mb-8 mb-8">
                Creating a healthy environment in the office is essential for both employees and the
                company success. A healthy workplace culture can boost morale, improve productivity,
                and reduce employee turnover. Encouraging good habits like taking regular breaks,
                promoting physical activity, and providing healthy food options can make a
                significant impact on employee well-being. It also important to maintain a clean and
                organized workspace, as it can positively affect mental health and concentration. In
                addition, fostering a positive workplace culture that values respect, open
                communication, and teamwork can create a supportive and motivating environment. By
                prioritizing a healthy office culture, businesses can create a positive and thriving
                workplace for everyone.
              </p>
            </div>
            {/* <div className="my-10">
          <button
            onClick={togglePopup}
            className="bg-orange-500 w-[100px] rounded-2xl font-medium py-2 text-white"
          >
            Apply Now
          </button>
        </div> */}
          </div>
          {/* <div className="w-full md:w-full flex justify-end"> */}
          <img src={ourCulture} alt="" className="w-full md:w-1/2 lg:w-[50%]" />
          {/* </div> */}
        </div>
      </div>
      {showPopup && <Model />}
    </div>
  );
};
