import React from "react";
import { HashLink } from "react-router-hash-link";
import ApplyForm from "../ApplyForm";

const ProjectManagerApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Project Manager
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold">Job Summary</h2>
          <p className="text-start mt-2">
            This position involves the day-to-day management of assigned projects, ensuring all
            aspects of the data collection lifecycle are completed in a client-focused manner.
          </p>
          <h2 className="text-start text-2xl font-bold mt-6">Functional Responsibilities</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">Coordinate resources across departments within the company</li>
            <li className="mt-4">
              Oversee project process including set-up, programming, testing, data collection,
              coding, and data tabulation
            </li>
            <li className="mt-4">
              Monitor and report on project progress while ensuring budget, timeline and
              deliverables are met
            </li>
            <li className="mt-4">
              Communicate accurately and thoroughly during project lifecycles, with both internal
              departments and clients
            </li>
            <li className="mt-4">
              Act as the client’s key point of contact throughout the project lifecycle
            </li>
            <li className="mt-4">
              Analyse project performance and proactively offer suggestions for improvement/identify
              areas of concern
            </li>
            <li className="mt-4">Work with field and conduct briefings (Phone)</li>
            <li className="mt-4">Manage sample providers (Web)</li>
            <li className="mt-4">Ensure project database is organized and up to date</li>
            <li className="mt-4">
              Close project and complete all documentation within expected timeline
            </li>
            <li className="mt-4">Complete final review of test links</li>
            <li className="mt-4">Pull and manipulate CATI sample using vendor software (Phone)</li>
            <li className="mt-4">Email invite set up and deployment (Web)</li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Skills Required</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Demonstrated knowledge of quantitative research methodologies and strong understanding
              of relevant industry software (Voxco, Confirmit, etc.).
            </li>
            <li className="mt-4">Competence with Microsoft Word and Excel</li>
            <li className="mt-4">Superior oral and written communication skills</li>
            <li className="mt-4">
              Outstanding organizational skills- able to handle multiple priorities and projects
            </li>
            <li className="mt-4">
              Strong attention to detail, excellent time management skills and deadline oriented
            </li>
            <li className="mt-4">
              An ongoing focus on excellent client service via polished communications and project
              awareness
            </li>
            <li className="mt-4">
              Ability to establish priorities and proceed with objectives with a high degree of
              quality without supervision.
            </li>
          </ul>
          <p className="mt-4 text-start">
            <b>Salary 3-6 LPA</b>
          </p>
          <p className="mt-2 text-start">
            <b>Experience 2-4 Years</b>
          </p>
          <div id="application-form">
            <ApplyForm type={"Project Manager"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagerApplyNow;
