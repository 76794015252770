import React from "react";
import { HashLink } from "react-router-hash-link";
import dropCV from "../../assets/drop-cv.svg";
import ApplyForm from "../ApplyForm";

const SalesAdministratorApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Sales Administrator
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold">Job Summary</h2>
          <p className="text-start mt-2">
            The Client Success Manager works with Zamplia’s API-based partners through the
            establishment of strategic relationships with new and existing users, setting critical
            performance goals, and then acting as a consultative resource to help partners achieve
            their goals. The Manager is also responsible for training and onboarding new users,
            evaluating partner growth strategies, generating usage reports, and maximising up sell
            opportunities by engaging with Sales, Solutions, Product Development, Product
            Management, Support, and Training as necessary.
          </p>
          <h2 className="text-start text-2xl font-bold mt-6">
            Essential Duties and Responsibilities – Supporting the Sales Process
          </h2>
          <p className="text-start mt-2">
            Become thoroughly familiar with Logit Group business practices, the market dynamics,
            competitive environment and company strengths and weaknesses. Specifically, on-line, or
            qualitative sample requests:
          </p>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Online Projects – sample only or full-service, programming, project management, or a
              combination thereof
            </li>
            <li className="mt-2">International – quant and qual</li>
            <li className="mt-2">Capture and document client project requirements</li>
            <li className="mt-2">
              Document client requirements for market research survey sample (offline and online)
              and services daily be proactive in providing pricing quote responses
            </li>
            <li className="mt-2">
              Analyze the feasibility of delivering against client requirements using quantitative
              models and formulas for online{" "}
            </li>
            <li className="mt-2">
              Send bid requests to appropriate field and online partner(s) for costs and feasibility
            </li>
            <li className="mt-2">
              Communicate between clients and internal account manager, internal/external bidding
              partners
            </li>
            <li className="mt-2">
              Collate all partner costs received and assist in partner evaluation and selection
              compare costs and decide what is more feasible and what we can get the more margin on
              money wise.
            </li>
            <li className="mt-2">
              Create custom pricing quotes in response to client needs for market research projects
              and services
            </li>
            <li className="mt-2">
              Operate within a team of other Sales Professionals providing fast and reliable quote
              support turnaround
            </li>
            <li className="mt-2">
              Communicate with Clients and Prospective Clients who have questions about market
              research survey quote responses
            </li>
            <li className="mt-2">Return the quote to client or salesperson directly</li>
            <li className="mt-2">
              Support the sales function through specific project-level aspects including:
            </li>
            <ul className="text-start list-disc pl-8 mt-4">
              <li className="mt-2">
                Creating quotes and contracts using online, offline templates and LOOP
              </li>
              <li className="mt-2">Introducing the client to the client services team via email</li>
              <li className="mt-2">
                Respond to PM generated fieldwork change requests including, but not limited to,
                changes, project specification changes, etc.
              </li>
            </ul>
            <li className="mt-2">
              Support the sales function in other general areas as needs arise
            </li>
            <li className="mt-2">Other duties may be assigned</li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Experience</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">1+ years as Sales Support professional is preferred</li>
            <li className="mt-4">Market Research experience would be considered beneficial</li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Skills and Knowledge</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">Strong communication skills</li>
            <li className="mt-4">Strong analytical skills</li>
            <li className="mt-4">
              Strong and comfortable computer user across variety of platforms including a full
              range of Microsoft Office Suite products i.e. with MS Excel and Word, Outlook
            </li>
            <li className="mt-4">Detail oriented</li>
            <li className="mt-4">
              Excellent time management/organizational skills – must be able to handle several
              projects/clients at once, and must be able to respond to client/AM requests in a
              timely fashion
            </li>
            <li className="mt-4">Fluent in English language – written and verbal</li>
            <li className="mt-4">Excellent communication skills</li>
            <li className="mt-4">Excellent math skills</li>
            <li className="mt-4">
              Workday will be largely based on North American business hours (Eastern time),
              although they may vary depending on business requirement
            </li>
          </ul>
          <div id="application-form">
            <ApplyForm type={"Sales Administrator"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesAdministratorApplyNow;
