import React from "react";
import groupim from "../../assets/digital-image.png";

export const EmpoweringBrands = () => {
  return (
    <div>
      <div className="text-white pt-30">
        <div className="mt-8 font-sans">
          <div className="flex flex-col-reverse items-center w-10/12 max-w-6xl gap-6 mx-auto md:flex-row md:gap-0">
            <div className="w-full my-3 md:w-10/12 lg:w-10/12 ">
              <p className="w-11/12 text-3xl leading-7 text-gray-400 md:text-3xl">
                <span className="text-teal-400">
                  Digitally empowering brands &
                  <br />
                  startups that are shaping our lives every day
                </span>
              </p>
              <br />
              <p className="w-11/12 leading-7 text-justify text-gray-500">
                According to a recent industry axiom, every business, whether a fast-food restaurant
                or a manufacturer of industrial gear, is now a tech company. According to the
                theory, all businesses are increasingly data-driven. Whether consumers or B2B
                customers are buying, most people expect their preferred brands or vendors to have a
                digital storefront or interface.
              </p>
              <br />
              <p className="w-11/12 leading-7 text-justify text-gray-500">
                Truly, all organizations are evolving into tech enterprises. To drive the next wave
                of innovation, they are investing in creating teams of data scientists, software
                engineers, UI experts, and others. The next frontier for banks, grocery stores, and
                biopharmaceutical firms appears to be online.
              </p>
              <br />
              <p className="w-11/12 leading-7 text-justify text-gray-500">
                Built In is continually examining the direction the IT sector is taking and how it
                will get there. It&apos;s becoming more and more obvious to us that the newest
                innovations from Silicon Valley startups or from companies like Facebook, Amazon,
                Apple, Microsoft, and Google just represent a portion of the whole picture.
              </p>
              <br />
            </div>
            <div className="flex items-center justify-center w-full md:w-full ">
              <img src={groupim} alt="" className="md:min-w-full lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
