import React from "react";
import { HashLink } from "react-router-hash-link";
import dropCV from "../../assets/drop-cv.svg";
import ApplyForm from "../ApplyForm";

const SurveyProgrammerApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">
            Survey Programmer
          </h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold mt-6">Functional Responsibilities</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Scripting market research surveys using different software platforms, including Voxco,
              Q-Fi, Decipher, and others.
            </li>
            <li className="mt-4">
              Leveraging back-end databases to integrate functionality across platforms.
            </li>
            <li className="mt-4">
              Integration of other platforms, including data reporting and graphical display, into a
              cohesive, client-facing output.
            </li>
            <li className="mt-4">Image processing and media integration into various platforms</li>
            <li className="mt-4">Communicating issues to the Project Manager/Account Manager.</li>
            <li className="mt-4">
              Reviewing client-provided documentation, providing feedback and potential amendments.
            </li>
            <li className="mt-4">
              Working to creatively solve the problems and meet the challenges presented by the
              business needs.
            </li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Skills Required</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">Web programming (HTML, JavaScript, CSS) and layout experience.</li>
            <li className="mt-4">Demonstrable SQL programming experience</li>
            <li className="mt-4">
              Knowledge of Python, Perl and working with large data sets to produce and integrate
              data across applications
            </li>
            <li className="mt-4">Media – video and photo – editing capabilities preferred.</li>
          </ul>
          <p className="mt-4 text-start">
            <b>Salary 3-6 LPA</b>
          </p>
          <p className="mt-2 text-start">
            <b>Experience 2-4 Years</b>
          </p>
          <div id="application-form">
            <ApplyForm type={"Survey Programmer"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyProgrammerApplyNow;
