export function PandoUniqueText() {
  return (
    <div className="text-center">
      <p className="text-[6rem] md:text-[12rem] lg:text-[12rem] sm:text-[12rem] opacity-5 md:opacity-5">
        Blogs
      </p>
      <h3 className="text-lg md:text-xl text-gray-500 relative bottom-[80px] md:bottom-[150px] sm:bottom-[172px] lg:bottom-[150px]">
        Stay up-to date with us
      </h3>
    </div>
  );
}
