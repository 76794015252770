import React from "react";

export const Trending = () => {
  return (
    <div className="md:mt-10 ">
      <h2 className="flex justify-center mb-2 text-xl font-bold text-gray-500 sm:text-2xl md:text-4xl lg:text-4xl">
        Trending Opportunities
      </h2>
      <p className="flex justify-center mb-2 text-[8px] sm:text-[9px] md:text-[9px] lg:text-[17px] font-medium text-center text-gray-400 md:mt-2">
        {" "}
        Challenges are the core of life and with us, we assure you to have the same competitive{" "}
        <br />
        and healthy work environment.
      </p>
    </div>
  );
};
