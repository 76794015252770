

import React, { useState } from "react";
import student from "../../assets/Group 77.svg";
import easydocuments from "../../assets/Group 76.svg";
import xbinlegal from "../../assets/Group 74.svg";
import kb from "../../assets/Group 75.svg";
import info from "../../assets/info.jpg";
import more from "../../assets/clock.png";

// Remove info from productLinks since it will not be a link
const productLinks = {
  student: "https://studentscorners.com/",
  pdf: "https://easydocoments.com/",
  xbinlegal: "https://xbinlegal.pandoconsultants.com/",
  kabadi: "https://kabadijee.com/",
};

export const Products = ({ toggle }: { toggle: () => void }) => {
  const [hovered, setHovered] = useState<string | null>(null);

  const hoverStyle = {
    color: "black",
    textDecoration: "underline",
  };

  const defaultStyle = {
    color: "#26C0B6",
    textDecoration: "none",
  };

  const imageSize = {
    width: "100%",
    height: "auto",
    maxWidth: "110px", 
    maxHeight: "110px",
  };


  
  return (
    <div className="absolute left-0 top-[80px] w-full h-auto overflow-x-auto">
      <div className="w-full relative bg-[#FFFFFF] text-center">
        <div className="w-10/12 mx-auto">
          <ul className="list-none flex flex-wrap justify-start pb-10 text-lg">
            {Object.entries(productLinks).map(([key, url], index) => (
              <li
                key={key}
                className="w-full sm:w-1/2 lg:w-1/3 text-start my-4 flex items-start"
                onMouseEnter={() => setHovered(key)}
                onMouseLeave={() => setHovered(null)}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <div
                    className="flex items-center justify-center overflow-hidden relative"
                  
                    style={imageSize} 
                  >
                    <img
                      src={
                        key === "student"
                          ? student
                          : key === "pdf"
                          ? easydocuments
                          : key === "xbinlegal"
                          ? xbinlegal
                          : key === "kabadi"
                          ? kb
                          : info
                      }
                      alt={key}
                      className="object-contain w-full h-full"
                    />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p
                      className="pt-1 text-xl font-semibold"
                      style={hovered === key ? hoverStyle : defaultStyle}
                    >
                      {key === "student"
                        ? "Student's Corner"
                        : key === "pdf"
                        ? "Easy Documents"
                        : key === "xbinlegal"
                        ? "Xbinlegal"
                        : key === "kabadi"
                        ? "Kabadi Jee"
                        : "The Info"}
                    </p>
                    {key === "student" && (
                      <p className="text-black pt-1 text-base">Gain best knowledge here</p>
                    )}
                    {key === "pdf" && (
                      <p className="text-black pt-1 text-base">
                        Your one step Solution for all Documents Need
                      </p>
                    )}
                    {key === "xbinlegal" && (
                      <p className="text-black pt-1 text-base">
                        Empowering Start-ups with hassle free Solution
                      </p>
                    )}
                    {key === "kabadi" && (
                      <p className="text-black pt-1 text-base">
                        We will collect your scrap and will help you
                      </p>
                    )}
                    {key === "kabadi" && (
                      <p className="text-black pt-1 text-base">to know the worth of your scrap</p>
                    )}
                  </div>
                </a>
              </li>
            ))}
            <li
              className="w-full sm:w-1/2 lg:w-1/3 text-start my-4 flex items-start"
              onMouseEnter={() => setHovered("info")}
              onMouseLeave={() => setHovered(null)}
              style={{ position: "relative", overflow: "hidden" }}
            >
              <div className="flex items-center">
                <div
                  className="flex items-center justify-center overflow-hidden relative"
                  style={imageSize}
                >
                  <img src={info} alt="The Info" className="object-contain w-full h-full" />
                </div>
                <div className="flex flex-col ml-4">
                  <p
                    className="pt-1 text-xl font-semibold"
                    style={hovered === "info" ? hoverStyle : defaultStyle}
                  >
                    The Info
                  </p>
                  <p className="text-black pt-1 text-base">
                    Dive into insightful blogs, participate in,
                  </p>
                  <p className="text-black pt-1 text-base">
                    engaging surveys, enhance your skills.
                  </p>
                </div>
              </div>
            </li>
            <li
              className="w-full sm:w-1/2 lg:w-1/3 text-start my-4 flex items-start"
              onMouseEnter={() => setHovered("person")}
              onMouseLeave={() => setHovered(null)}
              style={{ position: "relative", overflow: "hidden" }}
            >
              <div className="w-[80px] h-[80px] flex items-center justify-center overflow-hidden relative">
                <img src={more} alt="more" className="object-contain w-full h-full" />
              </div>
              <p
                className="pt-1 text-xl font-semibold ml-4"
                style={hovered === "person" ? hoverStyle : defaultStyle}
              >
                Coming soon....
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
