import React from "react";
import banner from "../../assets/software-banner.svg";
import software from "../../assets/software-ill.svg";
import software1 from "../../assets/software-ill1.svg";

const SoftwareDevelopmentRead = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Software Development
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                Computer programmers write programmes using a process called software development.
                The procedure, commonly referred to as the Software Development Life Cycle (SDLC),
                consists of a number of steps and offers a way to create products that meet both
                technical and user requirements.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-2 whitespace-pre-wrap">
                {" "}
                The SDLC can be used by software developers as a universal standard while creating
                and improving their computer programs. It offers a precise framework that software
                development teams can follow when creating, maintaining, and designing high-quality
                software. The aim of the software development process is to produce useful solutions
                within a predetermined budget and delivery timeframe.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="grow lg:w-full md:w-11/12 w-full text-start">
                  <p className="text-2xl font-bold text-black pb-4">
                    Phases in Software Development Process
                  </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-2">
                      <p>
                        <b>Needs Identification: </b>
                        Market research and brainstorming are part of the process needs
                        identification phase. A company must conduct in-depth market research to
                        assess the viability of the product before developing software.
                      </p>
                      <p className="mt-2">
                        To ensure that the intended users get the most out of the software and find
                        it necessary and desirable, developers must decide what features and
                        services the application should offer. This information can be gathered in a
                        variety of ways, such as surveys and comments from present and prospective
                        customers.
                      </p>
                    </li>
                    <li className="list-disc mt-2">
                      <p>
                        <b>Requirement Analysis: </b>
                        The another phase in the Software Development Life Cycle is Requirement
                        Analysis. Here, stakeholders have come to an understanding on the product,
                        user, and technological needs essential for the proposed product to succeed.
                        To produce a high-quality outcome, this phase clearly explains each
                        component, the scope, the duties of the developers, and the requirements for
                        testing.
                      </p>
                    </li>
                    <li className="list-disc mt-2">
                      <p>
                        <b>Design: </b>
                        The third phase of software development is design. Here are the incredibly
                        technical requirements that programmers and architects must follow to
                        produce the application. Participants will talk about things like risk
                        tolerance, team makeup, relevant technology, timing, cost, project
                        constraints, approach, and architectural design.
                      </p>
                    </li>
                    <li className="list-disc mt-2">
                      <p>
                        <b>Development and Implementation: </b>
                        The development and application of the design parameters is the following
                        phase. Developers write code in accordance with the specifications and
                        standards established for the product at earlier stages. Front-end
                        developers construct back-ends and interfaces in accordance with corporate
                        norms and requirements, and database administrators add pertinent data to
                        the database. Additionally, the programers review and test each other&apos;s
                        code.
                      </p>
                    </li>
                    <li className="list-disc mt-2">
                      <p>
                        <b>Testing: </b>
                        Before making a program available to users, a testing process examines its
                        functionality and looks for bugs. At this stage, qualified testers examine
                        the product&apos;s functionality to confirm that it functions in accordance
                        with the requirements analysis document.
                      </p>
                    </li>
                    <li className="list-disc mt-2">
                      <p>
                        <b>Maintenance: </b>
                        The software can be released to clients once it has been thoroughly tested
                        for errors. After a software&apos;s production version is released, the IT
                        software development business creates a maintenance team to handle any
                        problems users could have with the product. Hot fixes may be all that&apos;s
                        needed for minor maintenance issues, while updates are necessary for
                        problematic software.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={software} alt="work-content" className="mt-5" />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={software1} alt="work-content" className="mt-5 mb-10" />
              </div>
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start md:ml-10 mt-10">
                  <p className="text-2xl font-bold text-black">Types of Software</p>
                  <p className="mt-4">The two primary categories of software are:</p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">
                      <p>
                        <b>System Software: </b>
                        System software, often known as the operating system or OS, is the program
                        that your computer uses to convert input commands into a language that can
                        be understood by other machines. The operating system in a computer is in
                        charge of the hardware.
                      </p>
                    </li>
                    <li className="list-disc mt-4 mb-10">
                      <p>
                        <b>Application Software: </b>
                        The majority of individuals utilize this program to carry out chores on
                        their computers and smartphones. Examples that are often used include word
                        processors, web browsers, media players, photo editing programs, antivirus
                        software, and even software-as-a-service (SAS) goods.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentRead;
