import React from "react";
import banner from "../../assets/DevOps-banner.svg";
import devops from "../../assets/devops-ill.svg";
import devops1 from "../../assets/devops-ill1.svg";
import devops2 from "../../assets/deveops-ill2.svg";

const DevOpsReadMore = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          DevOps
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full/12 md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                The terms &quot;development&quot; and &quot;operations&quot; are combined to
                generate the term &quot;DevOps&quot;, which describes a collaborative or shared
                approach to the duties carried out by a company&apos;s application development and
                IT operations teams.
              </p>
              <p className="font-sans text-[#484848] text-center leading-relaxed p-2 whitespace-pre-wrap">
                {" "}
                The DevOps strategy, taken in its broadest sense, encourages improved communication
                and collaboration between these teams and other organizational divisions. The
                application of iterative software development, automation, and programmable
                infrastructure deployment and maintenance is what the term &quot;DevOps&quot; refers
                to in its simplest form. The word also alludes to cultural changes such as
                establishing trust and cohesion between developers and systems administrators and
                synchronizing technological endeavors with business requirements. DevOps can change
                the way software is delivered, as well as services, responsibilities, IT tools, and
                best practises.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">Working Of DevOps</p>
                  <p className="">
                    The goal of the DevOps methodology is to increase productivity across the board
                    over the entire software development lifecycle. A DevOps process consists of the
                    following steps: plan, code, build, test, release, deploy, operate, monitor, and
                    through a feedback plan, which resets the cycle.
                  </p>
                  <p className="mt-2">
                    An IT team should create software that fully satisfies user expectations,
                    installs quickly, and functions as intended right immediately, according to
                    DevOps. Organizations accomplish this by fusing culture and technology.
                  </p>
                  <p className="mt-2">
                    Developers and stakeholders collaborate on incremental updates that go live
                    independently of one another in order to align software to expectations.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={devops} alt="work-content" className="" />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2 flex justify-center">
                <img src={devops1} alt="work-content" className="mt-5" />
              </div>
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start lg:ml-12 md:ml-5">
                  <p className="text-2xl font-bold text-black mt-8">Advantages Of DevOps</p>
                  <p className="mt-4">There are four types of Blockchains:</p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">
                      the enhancement of the complete pipeline for delivering software via builds,
                      tests, and deployment.
                    </li>
                    <li className="list-disc mt-4">
                      communication between IT groups has improved and silos have been reduced.
                    </li>
                    <li className="list-disc mt-4">shorter software time to market.</li>
                    <li className="list-disc mt-4">rapid development based on input.</li>
                    <li className="list-disc mt-4">
                      Due to automation, there will be less downtime and menial labour.
                    </li>
                    <li className="list-disc mt-4">
                      The development process has been expedited by increased accountability and
                      ownership of the code.
                    </li>
                    <li className="list-disc mt-4">Broader roles and skills.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black">Disadvantages Of DevOps</p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">Development and IT tool proliferation;</li>
                    <li className="list-disc mt-4">Unnecessary, fragile, or unsafe automation;</li>
                    <li className="list-disc mt-4">
                      Scaling DevOps across multiple projects and teams;{" "}
                    </li>
                    <li className="list-disc mt-4">
                      Deployment is riskier due to a fail-fast mentality and job generalization as
                      opposed to specialization.
                    </li>
                    <li className="list-disc mt-4">
                      Conformity with regulations, particularly where role separation is necessary:
                    </li>
                    <li className="list-disc mt-4">
                      New job roles are some of the organizational and IT departmental changes that
                      must be addressed.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={devops2} alt="work-content" className="mb-10" />
              </div>
            </div>
          </div>
          <div className="w-11/12">
            <p className="text-start text-2xl font-bold text-black mt-8">DevOps Tools</p>
            <p className="text-start mt-2">
              DevOps is a way of thinking, not a set of technologies. Nonetheless, an IT team finds
              it challenging to do any task without the proper tools.. A CI/CD pipeline, cloud
              hosting, and containers are tools that DevOps practitioners regularly employ. Tools
              may be distributed using either proprietary or open-source software, or they may fit
              into neither category.
            </p>
            <p className="text-start text-2xl font-bold text-black mt-4">Code Repositories</p>
            <p className="text-start mt-2">
              Version-controlled source code repositories enable developers to work together on
              code. By checking out and checking back in the code, developers can return to a
              previous version. These programs keep track of source code modifications. Without
              tracking, it might be challenging for developers to maintain track of the most recent
              changes and the user-accessible code versions.
            </p>
            <p className="text-start text-2xl font-bold text-black mt-4">Artifact Repositories</p>
            <p className="text-start mt-2">
              For testing, a source code artifact is produced. Artifact repositories enable
              object-based outputs with version control. Artifact management can be justified using
              the same arguments as version-controlled source code management. Two examples of
              artifact repositories are Nexus Repository and JFrog Artifactory.
            </p>
            <p className="text-start text-2xl font-bold text-black mt-4">Cloud Environment</p>
            <p className="text-start mt-2 mb-10">
              DevOps businesses commonly use cloud infrastructure simultaneously because they can
              automate cloud infrastructure deployment, scaling, and other administrative tasks. Two
              of the most well-known cloud services are AWS and Microsoft Azure. Many cloud
              companies also offer CI/CD services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevOpsReadMore;
