import React from "react";
import Alarm from "../../assets/alarm.png";
import Playstore from "../../assets/playstoreg.png";
import easydocuments from "../../assets/easydoc.png";
import StudentCorner from "../../assets/studentc.png";
import './index.css';
import "@fontsource/poppins";

export const Launching = () => {
  return (
    <div className="flex flex-col items-center py-12 bg-gray-50   min-h-full m-4 place-items-center">
      {/* Title Section */}
      <h1 className="text-3xl font-bold mb-4 text-center">Explore Our Digital Products</h1>
      <p className="text-gray-600 text-center mb-12 max-w-2xl">
        Enhance your digital experience with our innovative apps and websites—explore and connect
        today.
      </p>

      {/* Product Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center place-items-center m-3 md:m-8 ">
        {/* First Card */}
        <div className="bg-blue-100 p-6 rounded-xl shadow-lg flex flex-col items-center w-full h-full">
          <div className="bg-[#324DDD] h-36 w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={StudentCorner} className="w-56 " alt="Student Corner" />
          </div>
          <h3 className="text-lg font-semibold mb-2 text-center">
            Enhance your digital experience day
          </h3>
          <p className="text-center text-gray-600 mb-4">
            Enhance your digital experience with our innovative apps and websites—explore and
            connect today.
          </p>
          <div className="flex justify-between w-full space-x-2 mt-auto">
            <button className="flex items-center bg-black text-white px-4 py-2 rounded-xl">
              <a href="#">
                <img src={Playstore} alt="Google Play" className="mr-2 md:w-48 sm:w-36" />
              </a>
            </button>
            <a href="https://studentscorners.com/">
              <button className="bg-[#324DDD] md:px-5 md:py-7 p-3  text-white px-4 py-2 rounded-lg">
                Visit Website
              </button>
            </a>
          </div>
        </div>

        {/* Second Card */}
        <div className="bg-teal-100 p-6 rounded-xl shadow-lg flex flex-col items-center w-full h-full ">
          <div className="bg-[#14b2b8] h-36 w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={easydocuments} className="w-20 h-20 " alt="EasyDoc" />
          </div>
          <h3 className="text-lg font-semibold mb-2 text-center">
            Enhance your digital experience day
          </h3>
          <p className="text-center text-gray-600 mb-4">
            Enhance your digital experience with our innovative apps and websites—explore and
            connect today.
          </p>

          <div className="flex justify-between w-full space-x-2 mt-auto">
            <div className="">
              <a href="https://play.google.com/store/apps/details?id=com.pando.easydocuments&pcampaignid=web_share">
                <button className="flex items-center bg-black text-white px-4  md:py-8 xl:py-3 py-[7px] lg:py-9 rounded-xl easy">
                  <img src={Playstore} alt="Google Play" className="mr-2 md:w-48 sm:w-36" />
                </button>
              </a>
            </div>
            <a href="https://easydocoments.com/">
              <button className="bg-[#14b2b8] text-white md:px-5 md:py-7 p-3 rounded-lg">
                Visit Website
              </button>
            </a>
          </div>
        </div>

        {/*Third Card (Coming Soon) */}

        <div className="bg-orange-100 p-6 rounded-xl shadow-lg flex flex-col items-center w-full ">
          <div className="lg:h-[350px] md:h-[252px] h-[250px] w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={Alarm} className="w-28 h-24" alt="" />
          </div>
          <h3 className="text-lg font-semibold mb-2 text-center">Coming Soon</h3>
          <p className="text-center text-gray-600 mb-4">Stay Tuned!</p>
          <div className="flex justify-between w-full space-x-2 mt-auto"></div>
        </div>
      </div>
    </div>
  );
};
