import laptopBoy from "../../assets/boy-with-laptop.png";
import add from "../../assets/home-add.png";
export const DigitallyEmpowering = () => {
  return (
    <div>
      <div className="relative w-full justify-center my-12">
        <div className="relative">
          <img src={add} alt="" className="w-full h-40 lg:h-96 md:h-96" />
        </div>
        <div className="flex justify-end w-full">
          <div className="absolute top-0 w-2/3 justify-end h-full flex flex-col justify-center items-center">
            <p className=" sm:pt-16 pt-10 text-center text-xs lg:text-3xl md:text-2xl sm:text-lg text-md font-semibold leading-7 text-gray-400 md:w-full">
              <span className="text-gray-600 text-center">Digital Empowering Brands</span>
            </p>
            <p className="lg:pt-4 md:pt-4 sm:pt-1 text-xs lg:text-lg text-center text-gray-500 md:w-full md:text-sm">
              Digitally empowering brands & startups that are shaping our lives every day
            </p>
            <div className="justify-center mt-2 lg:mt-5 md:mt-5 mb-10 text-center transition ease-in-out">
              <button className="bg-orange-500 w-[80px] lg:w-[160px] md:w-[140px] rounded-md font-medium py-1 lg:py-2 md:py-2 sm:py-2 lg:text-lg text-white text-sm">
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
