import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import webBanner from "../../assets/advance-software.png";
import webBannerEmployee from "../../assets/Employee-engagement.png";
import webBannerClient from "../../assets/client-engagement.png";
import { HashLink } from "react-router-hash-link";

export const HeroSession = () => {
  return (
    <div className="relative w-full sm:w-full mb-16 h-full">
      <Carousel interval={5000} autoPlay={true} infiniteLoop={true} stopOnHover={false}>
        <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
          <div className="absolute md:mt-24 sm:mt-8">
            <div className="lg:w-full md:w-11/12 sm:w-7/12 w-[220px] text-center max-h-fit mt-2">
              <p className="text-justify bg-clip-text pl-2 lg:pl-12 md:pl-8 sm:pl-6 text-md font-semibold lg:text-6xl md:text-5xl sm:text-3xl text-lg">
                Advance Software
              </p>
              <p className="lg:mt-8 md:mt-5 sm:mt-1 text-justify w-3/4 md:w-7/12 lg:w-6/12 pl-2 lg:pl-12 md:pl-8 sm:pl-6 font-sans font-normal text-[#484848] lg:text-md md:text-lg text-xs">
                {" "}
                In the era of advanced technology, it is necessary to remain updated with the
                trending technology. Pando India Software Consultants is one of them where the
                latest technologies are prominently used.
              </p>
              <div className="flex pl-2 sm:mt-4 mt-2 lg:pl-12 md:pl-8 sm:pl-6">
                <HashLink
                  smooth
                  to=""
                  className="bg-orange-600 rounded-md font-medium py-1 px-2 lg:px-8 md:px-8 sm:px-6 md:py-2 lg:py-2 lg:text-lg text-sm text-[#FFFFFF] md:mt-5"
                >
                  Read More
                </HashLink>
              </div>
            </div>
          </div>
          <div className="lg:w-full">
            <img
              className="w-[100%] h-[40vh] lg:h-[8/12] md:h-[80vh] sm:h-[50vh]"
              src={webBanner}
            />
          </div>
        </div>
        <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
          <div className="absolute md:mt-24 sm:mt-8">
            <div className="lg:w-full md:w-11/12 sm:w-7/12 w-[220px] text-center max-h-fit mt-2">
              <p className="text-justify bg-clip-text pl-2 lg:pl-12 md:pl-8 sm:pl-6 text-md font-semibold lg:text-6xl md:text-5xl sm:text-3xl text-lg">
                Employee Engagement
              </p>
              <p className="lg:mt-8 md:mt-5 sm:mt-1 text-justify w-3/4 md:w-7/12 lg:w-6/12 pl-2 lg:pl-12 md:pl-8 sm:pl-6 font-sans font-normal text-[#484848] lg:text-md md:text-lg text-xs">
                {" "}
                We provide good employee engagement as our organization trains and encourages
                employees to succeed in their roles and beyond.
              </p>
              <div className="flex pl-2 sm:mt-4 mt-2 lg:pl-12 md:pl-8 sm:pl-6">
                <HashLink
                  smooth
                  to=""
                  className="bg-orange-600 rounded-md font-medium py-1 px-2 lg:px-8 md:px-8 sm:px-6 md:py-2 lg:py-2 lg:text-lg text-sm text-[#FFFFFF] md:mt-5"
                >
                  Read More
                </HashLink>
              </div>
            </div>
          </div>
          <div className="lg:w-full">
            <img
              className="w-[100%] h-[40vh] lg:h-[8/12] md:h-[80vh] sm:h-[50vh]"
              src={webBannerEmployee}
            />
          </div>
        </div>
        <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
          <div className="absolute md:mt-24 sm:mt-8">
            <div className="lg:w-full md:w-11/12 sm:w-7/12 w-[220px] text-center max-h-fit mt-2">
              <p className="text-justify bg-clip-text pl-2 lg:pl-12 md:pl-8 sm:pl-6 text-md font-semibold lg:text-6xl md:text-5xl sm:text-3xl text-lg">
                Client Engagement
              </p>
              <p className="lg:mt-8 md:mt-5 sm:mt-1 text-justify w-3/4 md:w-7/12 lg:w-6/12 pl-2 lg:pl-12 md:pl-8 sm:pl-6 font-sans font-normal text-[#484848] lg:text-md md:text-lg text-xs">
                {" "}
                Every organization is known because of its clients and Pando India Software
                Consultant is the organization that provides the best services to clients.
              </p>
              <div className="flex pl-2 sm:mt-4 mt-2 lg:pl-12 md:pl-8 sm:pl-6">
                <HashLink
                  smooth
                  to=""
                  className="bg-orange-600 rounded-md font-medium py-1 px-2 lg:px-8 md:px-8 sm:px-6 md:py-2 lg:py-2 lg:text-lg text-sm text-[#FFFFFF] md:mt-5"
                >
                  Read More
                </HashLink>
              </div>
            </div>
          </div>
          <div className="lg:w-full">
            <img
              className="w-[100%] h-[50vh] lg:h-[8/12] md:h-[80vh] sm:h-[80vh]"
              src={webBannerClient}
            />
          </div>
        </div>
      </Carousel>
    </div>
  );
};
