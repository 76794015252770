import axios from "axios";

const getAccessToken = function () {
  try {
    return JSON.parse(window.localStorage.getItem("auth_ls")).access_token;
  } catch (e) {
    return null;
  }
};
const baseURL =
  process.env.REACT_APP_API_SERVER || "https://pandobackend-staging.azurewebsites.net/";

export const axiosClient = axios.create({
  baseURL,
  headers: {
    access_token: getAccessToken(),
  },
  withCredentials: true,
});

export const applyJobs = async (formData: any) => {
  try {
    const apiUrl = "https://apipando.pandoconsultants.com/api/applyJob";
    const response = await axios.post(apiUrl, formData);
    return response.data;
  } catch (error) {
    return error?.message;
  }
};

