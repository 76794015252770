import React from "react";
import banner from "../../assets/banking-banner.png";
import banking from "../../assets/banking-ill.png";
import bankingImg from "../../assets/banking-ill2.png";

export const Banking = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banking" />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Banking
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full md:w-10/12 content-center">
              <p className="font-sans text-[#484848] text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                Cash, credit, and other financial transactions are managed by the banking industry
                for both private individuals and business entities. The banking industry, which
                offers the liquid capital that consumers and businesses need to invest in the
                future, is one of the key drivers of the American economy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">Definition of Banking</p>
                  <p className="">
                    Available for a range of banking activities are financial institutions that
                    accept deposits from individuals and other organizations and use those funds to
                    provide loans, make investments, and make money. Banks can be divided into
                    numerous groups based on the type of business they conduct. Commercial banks
                    provide services that are available to both individual customers and businesses.
                    Via retail banking, people and families can get access to credit, deposits, and
                    money management services.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={banking} alt="banking-image" className="mb-5 mt-5" />
              </div>
            </div>
          </div>
          <div className="text-start">
            <p className="text-2xl font-bold text-black mt-4">Types Of Banking</p>
            <ul className="pl-4">
              <li className="list-disc mt-4">
                <b>Community Banking: </b>Community banks are smaller than commercial banks. They
                concentrate on the local market. They establish relationships with their customers
                and provide more specialized services.
              </li>
              <li className="list-disc mt-4">
                <b>Internet Banking: </b>Internet banking on the internet provides these features.
                Other names for the sector include net banking, e-banking, and internet banking.
                Today, the majority of other banks offer online services. Many banks only conduct
                business online. Because they don&apos;t have any branches, they might pass along
                cost savings to the customer.
              </li>
              <li className="list-disc mt-4">
                <b>Savings and Loan Banking: </b>Savings and loans are specialist financial
                institutions created to assist in affordable house acquisition. These banks usually
                offer depositors a higher interest rate because they are generating capital to lend
                for mortgages.
              </li>
              <li className="list-disc mt-4">
                <b>Credit Unions: </b>Although they have different organizational structures from
                typical banks, credit unions are financial institutions that carry out many of the
                same tasks. Members of credit unions own them. Because of their ownership structure,
                they may provide more specialized services at lower costs. You must fall under their
                scope of membership in order to join. This could apply to locals as well as students
                or employees at higher education institutions.
              </li>
              <li className="list-disc mt-4">
                <b>Investment Banking: </b>Bond and stock IPOs are two ways that investment banking
                raises capital for companies. Moreover, they facilitate mergers and acquisitions.
              </li>
              <li className="list-disc mt-4">
                <b>Merchant Banking: </b>Similar services are available to small firms through
                merchant banking. They provide mezzanine financing, bridging financing, and
                commercial credit packages.
              </li>
              <li className="list-disc mt-4">
                <b>Sharia Banking: </b>The Islamic ban on interest rates is adhered to by sharia
                banking. Islamic banks also avoid lending to companies that deal in alcohol and
                gambling. In lieu of interest, borrowers profit-share with the lender. Islamic banks
                stayed away from the hazardous asset classes that caused the financial crisis of
                2008 as a result.
              </li>
            </ul>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black">How Does Banking Works?</p>
                  <p className="mt-2">
                    Savings accounts, CDs, and checking accounts are just a few of the tools that
                    banks provide to help customers manage their money responsibly. The Federal
                    Deposit Insurance Corporation safeguards them (FDIC). Also, banks provide savers
                    with a tiny percentage of their deposits depending on an interest rate.
                  </p>
                  <p className="mt-2">
                    The current practice of not requiring banks to maintain any percentage of each
                    deposit in reserve may be changed by the Federal Reserve. The reserve
                    requirement is the name of such a rule. Companies generate income by charging
                    loans a greater interest rate than they do deposits.
                  </p>
                  <p className="text-2xl font-bold text-black mt-4">Important Happenings</p>
                  <p className="mt-2">
                    After Congress abolished the Glass-Steagall Act, there was a period of
                    deregulation in the banking industry. According to this regulation, commercial
                    banks were not permitted to make risky ventures utilizing ultra-safe deposits.
                    After its abolition, the line between commercial banks and investment banks
                    became blurrier. Some commercial banks began to invest in derivatives, such as
                    mortgage-backed securities. Depositors panicked when they failed.
                  </p>
                  <p className="mt-2 mb-10">
                    By the time of the financial crisis in 2008, only a few sizable banks held the
                    majority of the assets in the American banking sector. Due to consolidation,
                    several banks grew to be too large to fail. The federal government has to save
                    them.If it hadn&apos;t, the collapse of the banks would have jeopardized the
                    entire American economy.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={bankingImg} alt="banking-image" className="mb-10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
