import React, { ChangeEvent, useState } from "react";
import dropCV from "../assets/drop-cv.svg";
import { applyJobs } from "../api/api";
import { type } from "os";
interface FormValues {
  firstName: string;
  lastName: string;
  file: File | null;
}
interface ApplyFormProps {
  type: string;
}
function ApplyForm(props: ApplyFormProps) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [showFileName, setShowFileName] = useState("");
  const [formData, setFormData] = useState<FormValues>({
    firstName: "",
    lastName: "",
    file: null,
  });
  const handleApplyChange = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!firstName || !email || !mobile) {
      return alert("Please fill in all mandatory fields!");
    }

    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email.");
      return;
    }

    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }
    const data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("email", email);
    data.append("mobile", mobile);
    data.append("linkedinUrl", linkedinUrl);
    data.append("type", props.type);
    if (formData.file) {
      data.append("file", formData.file);
    } else {
      return alert("Please upload the resume here!");
    }

    try {
      const response = await applyJobs(data);
      if (response.success) {
        alert(response.message);
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error(error);
      alert("Oops! Something went wrong, please contact support!");
    }
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    setShowFileName(file.name);
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  };
  return (
    <div>
      <h2 className="text-start text-5xl font-normal mt-10 mb-10">Application Form</h2>
      <div className="flex gap-1 md:gap-10 w-full md:w-1/2 my-5 item-center">
        <div className="w-full md:w-1/2 ">
          <label className="block mb-2 text-black text-start">
            First Name
            <span className="text-red-500 text-xl"> *</span>
          </label>
          <input
            type="text"
            placeholder="Enter your First Name"
            onChange={(e) => setFirstName(e.target.value)}
            className="block border border-grey-400 text-black focus:outline-none w-full px-3 py-2 text-sm text-start justify-start rounded-md"
          />
        </div>
        <div className="w-full md:w-1/2">
          <label className="block mb-2 text-black text-start">Last Name</label>
          <input
            type="email"
            placeholder="Enter your Last Name"
            onChange={(e) => setLastName(e.target.value)}
            className="block border border-grey-400 text-black focus:outline-none w-full px-3 py-2 text-sm text-start rounded-md"
          />
        </div>
      </div>
      <div className="w-full my-5 item-center">
        <div>
          <label className="block mb-2 text-black text-start">
            Email ID<span className="text-red-500 text-xl"> *</span>
          </label>
          <input
            type="email"
            placeholder="Enter your Email ID"
            onChange={(e) => setEmail(e.target.value)}
            className="block border border-grey-400 text-black focus:outline-none w-full md:w-1/2 px-3 py-2 text-sm text-start rounded-md"
          />
        </div>
      </div>

      <div className="w-full my-5 item-center">
        <div>
          <label className="block mb-2 text-black text-start">
            Mobile Number<span className="text-red-500 text-xl"> *</span>
          </label>
          <input
            type="tel"
            pattern="[0-9]{10}"
            placeholder="Enter your Mobile Number"
            onChange={(e) => setMobile(e.target.value)}
            className="block border border-grey-400 text-black focus:outline-none w-full md:w-1/2 px-3 py-2 text-sm text-start rounded-md"
          />
        </div>
      </div>

      <div className="w-full my-5 item-center">
        <div>
          <label className="block mb-2 text-black text-start">Linkedin Profile</label>
          <input
            type="text"
            onChange={(e) => setLinkedinUrl(e.target.value)}
            placeholder="Enter your Linkedin URL"
            className="block border border-grey-400 text-black focus:outline-none w-full md:w-1/2  px-3 py-2 text-sm text-start rounded-md"
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 bg-gray-200 border border-[#B0B0B0] rounded-md">
        <div className="flex justify-center mb-2">
          <img src={dropCV} className="mt-2" alt="CV-img" />
        </div>
        <p>Drop your CV/ Resume here</p>
        <p>or</p>
        <div className="flex justify-center mt-2 mb-3">
          <label className="block relative w-64 h-10 bg-orange-500 rounded-lg text-white m-2">
            <span className="absolute inset-0 flex items-center justify-center">
              {showFileName ? showFileName : "Select file to upload"}
            </span>
            <input
              type="file"
              onChange={handleChangeFile}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
          </label>
        </div>
      </div>
      <div className="w-full md:w-1/2 text-end mt-4">
        <p>
          You can Mail your CV with us on{" "}
          <span className="underline cursor-pointer">resume@pandoconsultants.com</span>
        </p>
      </div>
      <div className="w-full md:w-1/2 mt-4 mb-10">
        <button
          className="w-full text-lg h-[45px] bg-orange-500 rounded-lg font-medium px-10 text-white"
          onClick={handleApplyChange}
        >
          {" "}
          Submit Application
        </button>
      </div>
    </div>
  );
}

export default ApplyForm;
