import React from "react";
import { HashLink } from "react-router-hash-link";
import dropCV from "../../assets/drop-cv.svg";
import ApplyForm from "../ApplyForm";

const DataAnalystApplyNow = () => {
  const scrollToBottom = () => {
    const element = document.getElementById("application-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  return (
    <div>
      <div className="w-full text-center bg-[#99FFEF] h-60">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h1 className="lg:text-4xl md:text-2xl font-semibold mt-16 text-start">Data Analyst</h1>
          <p className="mt-2 text-start">
            <b>Work type:</b> Full-time
          </p>
          <p className="mt-1 text-start">
            <b>On-site Job</b>
          </p>
          <div className="flex justify-end items-center">
            <div className="absolute align-middle">
              <HashLink
                to=""
                onClick={scrollToBottom}
                className="bg-[#FC7318] mb-20 md:mb-24 md:w-[220px] w-[100px] text-sm lg:text-xl md:text-md h-[50px] text-center rounded-lg font-medium text-white flex justify-center items-center"
              >
                Apply Now
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-10">
        <div className="md:w-9/12 w-11/12 inline-block">
          <h2 className="text-start text-2xl font-bold mt-6">Functional Responsibilities</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Collect and summarize data from various sources and review it according to
              questionnaire
            </li>
            <li className="mt-4">
              Maintain quality checks via skip pattern and write small routines for data validation
            </li>
            <li className="mt-4">
              Process data using statistical software to conduct basic analysis
            </li>
            <li className="mt-4">
              Notify Programmers/PM for any programming errors and make sure correction has been
              made
            </li>
            <li className="mt-4">Apply weights as per supplied weight matrix</li>
            <li className="mt-4">Setup and compile tables according to client requirements</li>
            <li className="mt-4">
              Assist programmer in tracking studies to maintain tracking standards
            </li>
            <li className="mt-4">
              Maintain good relation internally and externally with clients and meet project
              deadlines
            </li>
          </ul>
          <h2 className="text-start text-2xl font-bold mt-6">Skills Required</h2>
          <ul className="text-start list-decimal pl-8 mt-4">
            <li className="mt-4">
              Ability to work effectively both with a team as well as independently
            </li>
            <li className="mt-4">Excellent time management and organizational skills</li>
            <li className="mt-4">
              Detailed focused and deadline oriented Strong Excel and SPSS or Wincross capabilities
            </li>
            <li className="mt-4">
              Detailed focused and deadline oriented Strong Excel and SPSS or Wincross capabilities
            </li>
            <li className="mt-4">Superior oral and written communication</li>
            <li className="mt-4">An ongoing focus and desire for excellent client service</li>
          </ul>
          <p className="mt-6 text-start">
            <b>Salary: 4-7 LPA</b>
          </p>
          <p className="mt-2 text-start">
            <b>Experience: 2-5 Years</b>
          </p>
          <div id="application-form">
            <ApplyForm type={"Data Analyst"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataAnalystApplyNow;
