import React from "react";
import careerScreen from "../../assets/career-banner.png";
import careerImg from "../../assets/Career-page.svg";
import { About } from "../../components/Career/About";
import { Trending } from "./Trending";
import { Cardsection } from "./Cardsection";
import { Joinsection } from "./Joinsection";
import { HashLink } from "react-router-hash-link";

export const CareerHome = () => {
  return (
    <div>
      {/* header */}
      <div className="flex flex-wrap md:h-auto sm:w-full lg:w-full">
        <div className="absolute mt-[2rem] md:mt-20 lg:mt-32 ">
          <div className="w-auto h-16 lg:h-36 md:h-32 sm:h-24 text-center">
            <p className="text-justify pl-2 lg:pl-20 md:pl-12 sm:pl-4 text-xs font-semibold text-[#3AB3AD] lg:text-4xl md:text-3xl sm:text-lg">
              Join us and shape the<br></br> future of technology<br></br> together
            </p>
            <br />
          </div>
          <div className="">
            <HashLink
              smooth
              to="/career/#career-card"
              className="bg-orange-500 rounded-lg font-medium py-2 px-3 sm:px-5 text-white lg:ml-20 md:ml-12 ml-[15px] text-xs sm:text-lg md:text-lg"
            >
              Apply here
            </HashLink>
          </div>
        </div>
        <div className="lg:w-full">
          <img className="object-cover lg:w-[100%] h-auto" src={careerScreen} />
        </div>
      </div>
      <About />
      <br />
      <br />
      <br />
      <br />
      <Trending />
      <br />
      <br />
      <br />
      <br />
      <Cardsection />
      <Joinsection />
    </div>
  );
};
