import React, { useState } from "react";
import Constant from "../../constraints/constant.json";

export const Model = () => {
  const [ispopUp, setIspopUp] = useState(true);
  const HidePopup = () => {
    if (ispopUp == false) {
      setIspopUp(true);
    } else {
      setIspopUp(false);
    }
  };

  return (
    <div>
      {ispopUp ? (
        <div
          id="scroll"
          className="absolute w-full z-10 sm:w-full z-5 h-auto top-[120px] left-0 flex justify-center px-3"
        >
          {" "}
          <div className="w-[550px] relative sm:h-[1000px] bg-white sm:rounded-[24px] sm:border-[1px] shadow-lg">
            <div className="absolute border rounded-[24px] sm:h-[1000px] w-[550px] bg-white sm:rounded-[24px]">
              <div className="absolute flex justify-end w-full p-3 text-xl text-teal-400 item-center">
                <button onClick={HidePopup}>x</button>
              </div>

              <div className="flex justify-center w-full my-10 item-center">
                <div className="text-center">
                  <p className="text-sm text-gray-500">Apply for Job:</p>
                  <h1 className="text-xl tracking-wider text-teal-500">Full Stack Developer</h1>
                  <p className="text-sm text-gray-500">At Pando India Software Consultants</p>
                </div>
              </div>

              <div className="flex justify-center w-full my-10 item-center">
                <div>
                  <input
                    type="text"
                    placeholder="your name"
                    className=" border
                    border-teal-400 focus:outline-none w-[400px] px-3 py-2 text-sm text-start rounded-md"
                  />
                </div>
              </div>
              <div className="flex justify-center w-full my-10 item-center">
                <div>
                  <input
                    type="gmail"
                    placeholder="your Email address"
                    className="border border-teal-400 focus:outline-none w-[400px] px-3 py-2 text-sm text-start rounded-md"
                  />
                </div>
              </div>
              <div className="flex justify-center w-full my-10 item-center">
                <div>
                  <input
                    type="number"
                    placeholder="Your Number"
                    className="border border-teal-400 focus:outline-none w-[400px] px-3 py-2 text-sm text-start rounded-md"
                  />
                </div>
              </div>
              <div className="flex justify-center w-full my-10 item-center">
                <div>
                  <textarea
                    className="block border-teal-400 focus:outline-none p-2.5 w-[400px] border text-sm rounded-lg "
                    placeholder="Message"
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-center my-10 item-center">
                <div className="border border-teal-400 w-[230px] h-[120px] rounded-lg">
                  <div className="text-center">
                    <p className="mt-16 text-sm text-gray-500">
                      Plaese Upload your <br /> CV / Resume
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full my-10 item-center">
                <div>
                  <input
                    type="text"
                    placeholder="Linkdin Profile "
                    className="border-b-2 border-teal-400 focus:outline-none w-[400px] px-3 py-2 text-sm text-start"
                  />
                </div>
              </div>
              <div className="flex justify-center w-full my-10 item-center">
                <div>
                  <textarea
                    className="block border-teal-400 focus:outline-none px-3 w-[400px] border-b-2 text-sm "
                    placeholder="How did you find out us"
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-center item-center">
                <div className="flex justify-center item-center w-[400px]">
                  <div className="h-5 ">
                    <input type="checkbox" className="border border-teal-400 " />
                  </div>
                  <div className="ml-2 text-sm">
                    <p className="mb-2 text-xs text-gray-500">
                      {" "}
                      I consent to receiving from the Company e-mail information about new job
                      offers, our events, trainings and other ongoing activities.
                    </p>

                    <p id="helper-checkbox-text" className="text-xs text-gray-500">
                      If you are unable to submit your details, then please share your recently
                      updated resume at {Constant.CAREER_EMAIL} .
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <div className="flex justify-start w-[400px] item-center my-10 ">
                  <div className="w-[400px]">
                    <button className="bg-orange-500 w-[130px] mt-5 rounded-md font-medium py-1 text-white">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
